import { AdminAnalyticsScreen, AdminManagementScreen } from 'hybridspace-admin';
import { HybridspaceError } from 'hybridspace-common/lib/components/HybridspaceError';
import { ADMIN_ROOT, SPACE_ANALYTICS, SPACE_MANAGEMENT } from 'hybridspace-common/lib/constants';
import { useDefaultAdminScreenId } from '../../utils/useDefaultAdminScreenId';
import { DevPanel } from 'hybridspace-dev-panel';
import {
    DEV_PANEL,
    PEOPLE,
    PlacesHomeRouteLayout,
    WORK_PLAN,
    WorkplanScreen,
} from 'hybridspace-home';
import { isFeatureEnabled } from 'owa-feature-flags';
import { type HttpStatusCode } from 'owa-http-status-codes';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import { PlacesBuildingPage } from 'places-explore';
import {
    HomeDashboard,
    HybridspaceHomeDashboardContent,
    MergedWorkGroupsModule,
} from 'places-people';
import { isPlacesExploreEnabled, isPlacesWorkGroupsEnabled } from 'places-settings';
import { SpaceManagementDashboard } from 'places-space-management';
import {
    allWorkGroupsRevalidate,
    NoWorkGroupsFound,
    WORKGROUPS_ROOT,
    WorkGroupsDashboard,
} from 'places-work-groups';
import React from 'react';
import { createRoutesFromElements, Navigate, Route, useParams } from 'react-router-dom';
import PlacesErrorBoundary from '../PlacesErrorBoundary';
import { HomeRouteId } from './HomeRouteId';
import { PlacesRootRouteLayout } from './PlacesRootRouteLayout';
import { PlacesRootRouteOutletContainer } from './PlacesRootRouteOutletContainer';
import { AllWorkGroupsLoader } from './RouteLoaders/AllWorkGroupsLoader';
import { placesBuildingLoader } from './RouteLoaders/PlacesBuildingLoader';
import { placesHomeRouteLayoutLoader } from './RouteLoaders/PlacesHomeRouteLayoutLoader';
import { setPeopleDate } from './RouteLoaders/sharedLoaderUtils/setPeopleDate';
import { trackLoaderPerf } from './RouteLoaders/sharedLoaderUtils/trackLoaderPerf';
import { WorkplanLoader } from './RouteLoaders/WorkplanLoader';
import { WorkplanWorkGroupsLoader } from './RouteLoaders/WorkplanWorkGroupsLoader';

import type { LoaderFunctionArgs } from 'react-router-dom';

function NavigateToPeople() {
    const mailboxInfo = getModuleContextMailboxInfo();
    return (
        <Navigate to={isPlacesExploreEnabled(mailboxInfo) ? `/${PEOPLE}` : `/`} replace={true} />
    );
}
function NavigateToWorkGroupInPeople() {
    const { workgroupId } = useParams();
    const mailboxInfo = getModuleContextMailboxInfo();

    return (
        <Navigate
            to={
                isPlacesExploreEnabled(mailboxInfo)
                    ? `/${PEOPLE}/workgroups/${workgroupId}`
                    : `/workgroups/${workgroupId}`
            }
            replace={true}
        />
    );
}

function NavigateToHome() {
    return <Navigate to={'/'} replace={true} />;
}

function NavigateToAdminHome() {
    const adminHomeTab = useDefaultAdminScreenId();
    return <Navigate to={adminHomeTab} replace={true} />;
}

function TrackPlacesBuildingLoader(args: LoaderFunctionArgs<any>) {
    return trackLoaderPerf(placesBuildingLoader, 'PlacesBuildingLoader', args);
}

function TrackPlacesHomeRouteLoader(args: LoaderFunctionArgs<any>) {
    return trackLoaderPerf(placesHomeRouteLayoutLoader, 'PlacesHomeRouteLayoutLoader', args);
}

function TrackPlacesWorkplanLoader(args: LoaderFunctionArgs<any>) {
    return trackLoaderPerf(WorkplanLoader, 'PlacesWorkplanLoader', args);
}

function TrackHybridGuidanceWorkGroupLoader(args: LoaderFunctionArgs<any>) {
    return trackLoaderPerf(WorkplanWorkGroupsLoader, 'HybridGuidanceWorkGroupLoader', args);
}

async function HomeRouteLayoutLoader(args: LoaderFunctionArgs<any>) {
    // TODO: remove this conditional when explore goes to WW
    const mailboxInfo = getModuleContextMailboxInfo();
    const isExplorePageEnabled = isPlacesExploreEnabled(mailboxInfo);
    if (isExplorePageEnabled) {
        return TrackPlacesHomeRouteLoader(args);
    }

    await setPeopleDate(args);

    return TrackPlacesWorkplanLoader(args);
}

// Adding temporal redirection https://outlookweb.visualstudio.com/MicrosoftPlaces/_workitems/edit/242239
// while home is deprecated in MetaOS
const HOME_TEMPORAL_REDIRECTION = 'home/*';
const WORKPLAN_TEMPORAL_REDIRECTION = `home/${WORK_PLAN}`;
const WORKPLAN_PEOPLE_TEMPORAL_REDIRECTION = `home/${PEOPLE}`;

const ROOT_ROUTE_SPLAT = `/*`;
const ADMIN_SPLAT = `${ADMIN_ROOT}/*`;

const PlacesExploreErrorBoundaryElement = (
    <PlacesErrorBoundary errorName={'PlacesExplorePageRoot'} />
);

const PlacesNotFoundBoundaryElement = <PlacesErrorBoundary errorStatus={404} />;

const WorkGroupsErrorElement = <HybridspaceError />;

/**
 * This function is called at the root of hybridspace-main-module. Check PlacesRouter.tsx.
 * Recommend to add stores access in the Component instead.
 */
export function createPlacesRoutes() {
    const mailboxInfo = getModuleContextMailboxInfo();
    const isExplorePageEnabled = isPlacesExploreEnabled(mailboxInfo);
    const isWorkGroupsEnabled = isPlacesWorkGroupsEnabled(mailboxInfo);

    return createRoutesFromElements(
        <Route
            id={HomeRouteId.ROOT}
            path="/"
            Component={PlacesRootRouteLayout}
            ErrorBoundary={PlacesErrorBoundary}
        >
            <Route
                id={HomeRouteId.ROOT_SPLAT}
                path={ROOT_ROUTE_SPLAT}
                Component={PlacesRootRouteOutletContainer}
            >
                <Route errorElement={PlacesExploreErrorBoundaryElement}>
                    {/* At the moment, we do not have a building explore page, so the only way we get to this page is with a building ID */}
                    {isExplorePageEnabled && (
                        <Route
                            id={HomeRouteId.BUILDINGS_DEEP_LINK}
                            path={`buildings/:buildingId`}
                            Component={PlacesBuildingPage}
                            loader={TrackPlacesBuildingLoader}
                        />
                    )}

                    <Route
                        id={HomeRouteId.HOME}
                        index={true}
                        Component={PlacesHomeRouteLayout}
                        loader={HomeRouteLayoutLoader}
                    />
                </Route>
                {/*Temporal redirections, that needs to be removed*/}
                <Route
                    id={HomeRouteId.WORKPLAN_HOME_REDIRECT}
                    path={WORKPLAN_TEMPORAL_REDIRECTION}
                    Component={NavigateToPeople}
                />
                <Route
                    id={HomeRouteId.PEOPLE_HOME_REDIRECT}
                    path={WORKPLAN_PEOPLE_TEMPORAL_REDIRECTION}
                    Component={NavigateToPeople}
                />
                <Route
                    id={HomeRouteId.HOME_REDIRECT}
                    path={HOME_TEMPORAL_REDIRECTION}
                    Component={NavigateToHome}
                />

                <Route id={HomeRouteId.WORK_PLAN} path={WORK_PLAN} Component={NavigateToPeople} />
                <Route loader={setPeopleDate}>
                    {!isExplorePageEnabled ? (
                        <Route path="*" element={PlacesNotFoundBoundaryElement} />
                    ) : !isWorkGroupsEnabled ? (
                        <Route
                            id={HomeRouteId.PEOPLE}
                            path={PEOPLE}
                            Component={WorkplanScreen}
                            loader={TrackPlacesWorkplanLoader}
                        />
                    ) : (
                        <Route
                            path={PEOPLE}
                            Component={WorkGroupsDashboard}
                            loader={AllWorkGroupsLoader}
                            shouldRevalidate={allWorkGroupsRevalidate}
                            errorElement={WorkGroupsErrorElement}
                            id={HomeRouteId.PEOPLE}
                        >
                            <Route Component={HomeDashboard} loader={TrackPlacesWorkplanLoader}>
                                <Route path="workgroups">
                                    <Route
                                        path=":workgroupId"
                                        Component={MergedWorkGroupsModule}
                                        loader={TrackHybridGuidanceWorkGroupLoader}
                                    />
                                    <Route index={true} Component={NoWorkGroupsFound} />
                                </Route>
                                {/* TODO: Once the navigation is updated to accommodate the new path, remove the route that is just the id */}
                                <Route
                                    path=":workgroupId"
                                    Component={MergedWorkGroupsModule}
                                    loader={TrackHybridGuidanceWorkGroupLoader}
                                />
                                <Route
                                    index={true}
                                    loader={WorkplanLoader}
                                    Component={HybridspaceHomeDashboardContent}
                                />
                            </Route>
                        </Route>
                    )}
                </Route>

                {isFeatureEnabled('msplaces-devPanel', mailboxInfo) && (
                    <Route id={HomeRouteId.DEV_PANEL} path={DEV_PANEL} Component={DevPanel} />
                )}
                <Route path="*" element={PlacesNotFoundBoundaryElement} />
                {isWorkGroupsEnabled && (
                    <Route path={WORKGROUPS_ROOT} id={HomeRouteId.WORK_GROUPS}>
                        <Route path=":workgroupId" Component={NavigateToWorkGroupInPeople} />
                        <Route index={true} Component={NavigateToPeople} />
                    </Route>
                )}
                <Route id={HomeRouteId.ADMIN} path={ADMIN_SPLAT}>
                    <Route index={true} Component={NavigateToAdminHome} />
                    {isFeatureEnabled('msplaces-space-management-page') && (
                        <Route
                            id={HomeRouteId.ADMIN_SPACE_MANAGEMENT}
                            path={SPACE_MANAGEMENT}
                            Component={SpaceManagementDashboard}
                        />
                    )}
                    {!isFeatureEnabled('msplaces-space-management-page') && (
                        <Route
                            id={HomeRouteId.ADMIN_SPACE_MANAGEMENT}
                            path={SPACE_MANAGEMENT}
                            Component={AdminManagementScreen}
                        />
                    )}
                    <Route
                        id={HomeRouteId.ADMIN_SPACE_ANALYTICS}
                        path={SPACE_ANALYTICS}
                        Component={AdminAnalyticsScreen}
                    />
                    <Route path="*" Component={NavigateToAdminHome} />
                </Route>
            </Route>
            <Route path="*" element={PlacesNotFoundBoundaryElement} />
        </Route>
    );
}
