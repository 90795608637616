import { useDefaultAdminScreenId } from '../../utils/useDefaultAdminScreenId';
import { useRoleContext, UserRoles } from 'hybridspace-common/lib/context';
import { PEOPLE } from 'hybridspace-home';
import { logUsage } from 'owa-analytics';
import loc from 'owa-localize';
import { observer } from 'owa-mobx-react';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import { isPlacesExploreEnabled } from 'places-settings';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    makeStyles,
    Menu,
    MenuButton,
    MenuItemRadio,
    MenuList,
    MenuPopover,
    MenuTrigger,
    tokens,
} from '@fluentui/react-components';
import { Eye20Filled } from '@fluentui/react-icons';
import strings from './HybridspaceRoleSwitcher.locstring.json';

import type { MenuItemRadioProps } from '@fluentui/react-components';
import { shouldStartPlacesAnalyticsAlt } from 'hybridspace-performance-datapoints';
import {
    cancelPlacesAnalyticsPerfEventsAction,
    placesAnalyticsAppLoadAction,
} from 'places-fwk-actions';

const useStyles = makeStyles({
    eyeIcon: {
        marginRight: '0.3rem',
        '@media (forced-colors: active)': {
            color: 'ButtonText',
        },
    },
    menuPopOver: {
        marginTop: '0.07rem',
        width: '10.7rem',
    },
    viewAsText: {
        color: tokens.colorPaletteCornflowerForeground2,
        fontSize: '0.81rem',
        marginLeft: '0.52rem',
    },
    rolesList: {
        marginTop: '0.3rem',
    },
    userRole: {
        fontSize: '0.85rem',
        marginTop: '0.2rem',
        marginBottom: '0.2rem',
    },
    tooltipMainComponent: {
        display: 'flex',
        flexDirection: 'column',
    },
});

export default observer(function HybridspaceRoleSwitcher() {
    const classes = useStyles();
    const { userRole, setUserRole } = useRoleContext();

    const navigate = useNavigate();
    const maiboxInfo = getModuleContextMailboxInfo();
    const defaultEmployeeTabId = isPlacesExploreEnabled(maiboxInfo) ? `/${PEOPLE}` : `/`;
    const defaultAdminTabId = useDefaultAdminScreenId();

    const handleRoleSwitch = React.useCallback<Exclude<MenuItemRadioProps['onClick'], undefined>>(
        _event => {
            const currentRole = _event.currentTarget.id;
            const previousRole = userRole;

            if (currentRole === previousRole) {
                return;
            }

            if (currentRole === UserRoles.Employee) {
                setUserRole(UserRoles.Employee);

                // This is to handle the case when user switches immediately to employee role after switching to admin role or directly after landing on space-analytics page.
                // In this case, we should cancel all the perf markers for analytics page if they are already running.
                cancelPlacesAnalyticsPerfEventsAction({
                    scenario: 'EmployeeRoleSwitchStart',
                    eventsToCancel: ['All'],
                });
                navigate(defaultEmployeeTabId);
            } else {
                setUserRole(UserRoles.Admin);
                // start the perf mark for admin role switch
                if (shouldStartPlacesAnalyticsAlt('/places' + defaultAdminTabId)) {
                    placesAnalyticsAppLoadAction({
                        scenario: 'AdminRoleSwitchStart',
                    });
                }
                navigate(defaultAdminTabId);
            }
            logUsage('HybridSpaceRoleSwitcher', {
                toRole: currentRole,
                fromRole: previousRole,
            });
        },
        [userRole, setUserRole, navigate, defaultEmployeeTabId, defaultAdminTabId]
    );

    const checkedValues = React.useMemo(() => {
        return {
            roles: [userRole],
        };
    }, [userRole]);

    return (
        <Menu defaultCheckedValues={checkedValues}>
            <MenuTrigger>
                <MenuButton appearance="subtle">
                    <Eye20Filled className={classes.eyeIcon} />
                    {userRole === UserRoles.Admin
                        ? loc(strings.hybridspaceAdmin)
                        : loc(strings.hybridspaceEmployee)}
                </MenuButton>
            </MenuTrigger>

            <MenuPopover className={classes.menuPopOver}>
                <span className={classes.viewAsText}>{loc(strings.hybridspaceViewAs)}</span>
                <MenuList className={classes.rolesList}>
                    <MenuItemRadio
                        name="roles"
                        value={UserRoles.Admin}
                        id={UserRoles.Admin}
                        className={classes.userRole}
                        onClick={handleRoleSwitch}
                    >
                        {loc(strings.hybridspaceAdmin)}
                    </MenuItemRadio>
                    <MenuItemRadio
                        name="roles"
                        value={UserRoles.Employee}
                        id={UserRoles.Employee}
                        className={classes.userRole}
                        onClick={handleRoleSwitch}
                    >
                        {loc(strings.hybridspaceEmployee)}
                    </MenuItemRadio>
                </MenuList>
            </MenuPopover>
        </Menu>
    );
}, 'HybridspaceRoleSwitcher');
