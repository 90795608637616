import React from 'react';
import { getManagedPerfDatapoint, type ManagedPerfDatapointType } from './getManagedPerfDatapoint';

const ManagedPerfDatapointContext = React.createContext<ManagedPerfDatapointType>(
    getManagedPerfDatapoint(
        undefined /* perfDatapint */,
        [] /* defaultWFMarkersToWait */,
        () => '' /* WFRangeToCheckpoint */,
        undefined /* dpEndCallback */
    )
);
ManagedPerfDatapointContext.displayName = 'ManagedPerfDatapointContext';

export const ManagedPerfDatapointContextProvider = ManagedPerfDatapointContext.Provider;
export const useManagedPerfDatapointContext = () => React.useContext(ManagedPerfDatapointContext);

export { getManagedPerfDatapoint, type ManagedPerfDatapointType } from './getManagedPerfDatapoint';
