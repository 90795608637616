import { addFloorPlanToCache } from '../selectors/addFloorPlanToCache';
import { getFloorPlanFromCache } from '../selectors/getFloorPlanFromCache';
import useFloorPlan from 'hybridspace-common/lib/serviceHooks/useFloorPlan';
import {
    PlacesMapsLoadE2ECustomData,
    PlacesMapsLoadE2ECheckmark,
} from 'hybridspace-performance-datapoints';
import React from 'react';
import sha256 from 'hash.js/lib/hash/sha/256';

import type { Floor, FloorPlan } from 'hybridspace-common/lib/serviceTypes';
import type { PlacesMapsTableEntry } from '../database';

const emptyFloorPlan = {
    floorId: null,
    floorPlan: new Map(),
    displayCenter: { lat: 0, lng: 0 },
};

/**
 * Custom hook to fetch floorPlan (map) data from both GetMapFeatures API (including apollo cache) and indexedDB.
 * Network query and indexedDB fetch are both triggered upon floorId change.
 * The hook returns once for each map version (if network query and indexedDB returns the same map,
 * the hook will only return data once).
 *
 * TODO: Replace hash check on client with Etag check, once GetMapFeatures API supports Etag.
 * WI on Etag support: https://outlookweb.visualstudio.com/MicrosoftPlaces/_workitems/edit/321740
 */
export default function useFloorPlanWithCache(
    floorId: string | null,
    skip: boolean = false,
    skipTelemetry: boolean = false
) {
    // Flag to indicate if cache fetch is still in progress.
    const [cacheFetchInProgress, setCacheFetchInProgress] = React.useState(false);

    /* eslint-disable-next-line owa-custom-rules/prefer-react-state-without-arrays-or-objects -- (https://aka.ms/OWALintWiki)
     *	> Justification: In our scenario the floor data has to be an object and has to be a React useState (instead of a plain object),
     * since we need this object to persist between re-renders and it needs to be shared with other hooks (useEffect, useMemo). */
    const [floor, setFloor] = React.useState<Floor>(emptyFloorPlan);

    /**
     * When floorId changes, try to fetch floor data from indexedDB.
     */
    React.useEffect(() => {
        if (skip || !floorId) {
            return;
        }

        setCacheFetchInProgress(true);

        getFloorPlanFromCache(floorId).then((result: PlacesMapsTableEntry | undefined) => {
            if (!!result && isFloorDataValid(result.floor, floorId)) {
                if (!skipTelemetry) {
                    PlacesMapsLoadE2ECheckmark('idbe' /* Get valid data from IndexedDB */);
                    PlacesMapsLoadE2ECustomData({ InIdb: true });
                }

                setFloor(result.floor);
            }

            setCacheFetchInProgress(false);
        });
    }, [floorId]);

    // Make a network call to GetMapFeatures API to get the most up-to-date map data.
    const {
        floor: floorFromNetwork,
        loading: networkLoading,
        error,
    } = useFloorPlan(floorId, skip || !floorId /** skip */);

    /**
     * When map is fetched from network request:
     * 1) Update floor useState.
     * 2) Update map data in indexedDB.
     */
    React.useEffect(() => {
        if (skip || !floorId) {
            return;
        }

        if (isFloorDataValid(floorFromNetwork, floorId)) {
            addFloorPlanToCache(floorFromNetwork);
            setFloor(floorFromNetwork);

            if (!skipTelemetry) {
                PlacesMapsLoadE2ECheckmark('gmfe' /* Get valid map data from network */);
                logFloorFromNetworkCustomData(floorFromNetwork.floorPlan);
            }
        }
    }, [floorFromNetwork, floorId]);

    const floorDataHash = React.useMemo(() => {
        return computeFloorPlanHash(floor.floorPlan);
    }, [floor.floorPlan]);

    /**
     * memoizedFloor is the actual floor plan being returned.
     * To avoid duplicate renderings, it only gets recomputed when floorId or floorDataHash changes.
     */
    const memoizedFloor: Floor = React.useMemo(() => {
        return floor;
    }, [floorId, floorDataHash]);

    const loadingState = {
        floor: emptyFloorPlan,
        loading: true,
        error: undefined,
    };
    const errorState = { floor: emptyFloorPlan, loading: false, error };
    const emptyState = {
        floor: emptyFloorPlan,
        loading: false,
        error: undefined,
    };

    if (!floorId || skip) {
        return emptyState;
    }

    if (error) {
        return errorState;
    }

    if (isFloorDataValid(memoizedFloor, floorId)) {
        /**
         * When we find valid floor data, return it.
         */
        return { floor: memoizedFloor, loading: false, error: undefined };
    } else {
        /**
         * This is when memoizedFloor contains map data but the floorId does not match our requested floorId.
         * This happens when we're switching floors and the new floor plan has not been fetched yet.
         */
        if (
            !!memoizedFloor &&
            hasFloorPlan(memoizedFloor.floorPlan) &&
            memoizedFloor.floorId !== floorId
        ) {
            return loadingState;
        }

        /**
         * When both cache fetch and network request are finished and no valid map is found, return empty floor.
         */
        if (!cacheFetchInProgress && !networkLoading && !isFloorDataValid(memoizedFloor, floorId)) {
            return emptyState;
        }

        /**
         * All other cases, return loading state.
         */
        return loadingState;
    }
}

function logFloorFromNetworkCustomData(floorPlan: FloorPlan) {
    let featuresCount: number = 0;
    let categoriesCount: number = 0;

    for (const [_, categories] of floorPlan) {
        categoriesCount += categories.size;

        for (const [__, features] of categories) {
            featuresCount += features.length;
        }
    }

    PlacesMapsLoadE2ECustomData({
        PayLoadSize: getFloorPlanSizeInBytes(floorPlan),
        FeaturesCount: featuresCount,
        CategoriesCount: categoriesCount,
    });
}

// Check if floor object exists and contains a valid floor plan.
function isFloorDataValid(floor: Floor | undefined, targetFloorId: string) {
    return !!floor && floor.floorId === targetFloorId && hasFloorPlan(floor.floorPlan);
}

function hasFloorPlan(floorPlan: Map<string, any>) {
    return floorPlan && floorPlan.size > 0;
}

function stringifyFloorPlan(floorPlan: FloorPlan) {
    const outerArray = Array.from(floorPlan.entries()).map(([key, innerMap]) => {
        const innerArray = Array.from(innerMap.entries());
        return [key, innerArray];
    });

    return JSON.stringify(outerArray);
}

function computeFloorPlanHash(floorPlan: FloorPlan) {
    return sha256().update(stringifyFloorPlan(floorPlan)).digest('hex');
}

function getFloorPlanSizeInBytes(floorPlan: FloorPlan): number {
    const jsonString = JSON.stringify(floorPlan, (_, value) => {
        if (value instanceof Map) {
            return Array.from(value.entries());
        }
        return value;
    });
    return new Blob([jsonString]).size;
}
