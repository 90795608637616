import type { DbSchema, Transaction } from 'dexie';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports -- (https://aka.ms/OWALintWiki)
 * BASELINE. Do not copy and paste!
 *	> 'dexie' import is restricted from being used. Importing from dexie is allowed but needs an additional approver */
import Dexie from 'dexie';
import { logUsage } from 'owa-analytics';
import { isFeatureEnabled } from 'owa-feature-flags';

const SLOW_IDB_TRANSACTION_THRESHOLD = 500;

export function initializeTransactionMeasurement(database: Dexie) {
    database._createTransaction = Dexie.override(database._createTransaction, originalFn => {
        return (
            mode: IDBTransactionMode,
            storeNames: ArrayLike<string>,
            dbschema: DbSchema,
            parentTransaction?: Transaction | null
        ) => {
            const transaction: Transaction = originalFn(
                mode,
                storeNames,
                dbschema,
                parentTransaction
            );

            if (!parentTransaction) {
                const start = performance.now();
                const stack = new Error('Trace').stack;
                let completed: boolean = false;
                const complete = (status: string) => {
                    if (!completed) {
                        completed = true;
                        const duration = performance.now() - start;
                        if (
                            duration > SLOW_IDB_TRANSACTION_THRESHOLD ||
                            isFeatureEnabled(
                                'fwk-verbose-idb-telemetry',
                                undefined /*malboxInfo*/,
                                true /*dontThrow*/
                            )
                        ) {
                            logUsage('slow_idb', {
                                durationMs: Math.floor(duration),
                                db: database.name,
                                stores: transaction.storeNames.join(','),
                                mode,
                                status,
                                stack,
                            });
                        }
                    }
                };
                transaction.on('complete', () => complete('complete'));
                transaction.on('error', () => complete('error'));
                transaction.on('abort', () => complete('abort'));
            }

            return transaction;
        };
    });
}
