import { PerformanceDatapoint } from 'owa-analytics';
import type { TraceErrorObject } from 'owa-trace';

type AttachmentOfflineAction = 'addAttachmentToDraft' | 'deleteAttachmentFromDraft';
type AttachmentOfflineStep = 'idbResolver' | 'resultProcessor' | 'orchestrator';
export type AttachmentOfflineDatapointOptions = {
    action: AttachmentOfflineAction;
    step: AttachmentOfflineStep;
};

export function getAttachmentOfflineActionDatapoint({
    action,
    step,
}: AttachmentOfflineDatapointOptions): PerformanceDatapoint {
    switch (action) {
        case 'addAttachmentToDraft':
            switch (step) {
                case 'idbResolver':
                    return new PerformanceDatapoint('AddAttachmentToDraftIdbResolver');
                case 'resultProcessor':
                    return new PerformanceDatapoint('AddAttachmentToDraftResultProcessor');
                case 'orchestrator':
                    return new PerformanceDatapoint('AddAttachmentToDraftOrchestrator');
            }
    }
    const error: TraceErrorObject = new Error('Invalid action or step');
    error.additionalInfo = { action, step };
    throw error;
}
