import { emitSyncEvent } from 'owa-offline-sync-diagnostics';
import type { AttachmentOfflineDatapointOptions } from 'owa-offline-attachments-logging';
import type { LogData } from 'owa-logging-utils';
import { type PerformanceDatapoint } from 'owa-analytics';

export function logAttachmentOfflineData(
    dp: PerformanceDatapoint,
    datapointOptions: AttachmentOfflineDatapointOptions,
    logData: LogData
) {
    dp.addCustomData(logData);
    emitSyncEvent(datapointOptions.action + ':' + datapointOptions.step, logData);
}
