import { AccNetworkLostToast } from 'accelerator-common/lib/AccNetworkLostToast';
import { getCurrentPage } from 'hybridspace-common/lib/utils/getCurrentPage';
import { isFeatureEnabled } from 'owa-feature-flags';
import loc from 'owa-localize';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import { SkipLinkRegion } from 'owa-skip-link/lib/components/SkipLinkRegion';
import { type UTPageType } from 'owa-unified-telemetry';
import { isPlacesPremiumEnabled } from 'places-settings';
import { isUserTimeZoneSet, PlacesTimeZoneDialog } from 'places-timezone-helpers';
import React from 'react';
import { Outlet, useFetcher, useLocation, useNavigation, useRevalidator } from 'react-router-dom';
import { mergeClasses, Spinner } from '@fluentui/react-components';
import { placesMainContent, placesSkipToMainContent } from './PlacesRootRouteLayout.locstring.json';
import {
    exploreOutletContainer,
    loadingBackground,
    outletContainer,
    spinner,
} from './PlacesRootRouteOutletContainer.scss';

let prefetchedPeople = false;
let prefetchedExplore = false;

export function PlacesRootRouteOutletContainer() {
    const navigation = useNavigation();
    const revalidator = useRevalidator();
    const isLoading = navigation.state === 'loading' || revalidator.state != 'idle';

    // We show the time zone dialog if the user has not set the time zone and the feature flight is enabled
    // or if the dev feature flag is enabled.
    const showSetTimeZone =
        (!isUserTimeZoneSet() && isFeatureEnabled('msplaces-show-timeZone-dialog')) ||
        isFeatureEnabled('msplaces-dev-show-timeZone-dialog');

    // Tab Prefetching
    // Will only prefetch if the user is premium and the data has not already been prefetched
    // Prefetching only gets applied to Explore and People pages
    const location = useLocation();
    const mailboxInfo = getModuleContextMailboxInfo();
    const fetcher = useFetcher();
    const currentPage = getCurrentPage(window.location.pathname);
    const isCurrentPageExplore = currentPage === 'Explore';
    React.useEffect(() => {
        const isPremiumUser = isPlacesPremiumEnabled(mailboxInfo);

        // Skip prefetching if the user is core or if data has already been prefetched
        if (!isPremiumUser || (prefetchedExplore && prefetchedPeople)) {
            return;
        }

        // Only try to prefetch if the fetcher is idle
        if (fetcher.state === 'idle') {
            switch (currentPage) {
                case 'Explore':
                    if (!prefetchedExplore) {
                        prefetchedExplore = true; // If our current page is Explore, we know we no longer need to prefetch it
                        fetcher.load('/people?throwError=false'); // Prefetch workgroups loader
                        fetcher.load('/people?index&throwError=false'); // Prefetch workplan loader
                        prefetchedPeople = true; // Mark People as prefetched after we call fetcher
                    }
                    break;
                case 'People':
                    if (!prefetchedPeople) {
                        prefetchedPeople = true; // If our current page is People, we know we no longer need to prefetch it
                        fetcher.load('/?index&noRedirect=true&throwError=false'); // Prefetch Explore page when on People page
                        prefetchedExplore = true; // Mark Explore as prefetched after we call fetcher
                    }
                    break;
                default:
                    break;
            }
        }
    }, [location.pathname, mailboxInfo, fetcher, currentPage]);

    return (
        <>
            <AccNetworkLostToast />
            {isCurrentPageExplore ? (
                <SkipLinkRegion
                    role="main"
                    skipLinkName={loc(placesSkipToMainContent)}
                    regionName={loc(placesMainContent)}
                    className={mergeClasses(
                        isLoading && loadingBackground,
                        outletContainer,
                        exploreOutletContainer
                    )}
                >
                    <Outlet />
                </SkipLinkRegion>
            ) : (
                <SkipLinkRegion
                    role="main"
                    skipLinkName={loc(placesSkipToMainContent)}
                    regionName={loc(placesMainContent)}
                    className={mergeClasses(isLoading && loadingBackground, outletContainer)}
                >
                    <Outlet />
                </SkipLinkRegion>
            )}
            {showSetTimeZone && <PlacesTimeZoneDialog />}
            {isLoading && (
                <div className={spinner}>
                    <Spinner appearance="primary" />
                </div>
            )}
        </>
    );
}
