import { today } from 'owa-datetime';
import { createAccessors } from 'owa-viewstate-store';
import { createStore, mutatorAction } from 'satcheljs';

import type { AppDefinition, CollaboratorGroup } from 'owa-graph-schema';
import type { OwaDate } from 'owa-datetime';
import type { UserEmailAddress } from 'accelerator-aad/lib/UserEmailAddress';
import type { WorkLocationInfo } from 'places-explore-operations';
import { ObservableMap } from 'mobx';

/**
 * this store is used to track values that are shared among
 * components in places-explore
 */
const getStore = createStore('places-explore-app-store', {
    currentDate: null as OwaDate | null,
    isMapAvailable:
        true /** Map availability has some complex logic, so instead of recalculating it, storing here */,
    recentBuildings: null as string[] | null, // Map of recent buildings the user has visited, key is building id and value is last visited date
    workLocationInfo: null as WorkLocationInfo | null, // this is user's FWH info, changes to this default value will break call to loadWorkLocationInfo
    collaboratorsLocations: null as Map<
        string,
        Map<CollaboratorGroup, UserEmailAddress[]>
    > | null /* converting to null/unknown first to know when data has been loaded already
        Stores collaborators with location info with building id as a key.
        ex. < BUILDING_ID : <'Saved' : [EMAIL_ADDRESS]>> */,
    rareVisitData: new ObservableMap<UserEmailAddress, string[]>(), // Map of rare visit data, key is collaborators' email and value is array of building Id's
    shouldFilterByRareVisits: false,
    buildingAppDefinitions: new ObservableMap<string, AppDefinition[]>(),
});

export function getCurrentDate() {
    // We cannot initialize a store with "today" because there is no guarantee
    // a store will be initialized before or after user time zone is loaded and
    // today() depends on the user's time zone being initialized.
    return getCurrentDateOrNull() ?? today();
}

export const store = getStore();

export const {
    getCurrentDate: getCurrentDateOrNull,
    setCurrentDate,
    setWorkLocationInfo,
    getWorkLocationInfo,
    setCollaboratorsLocations,
    getCollaboratorsLocations,
    getRecentBuildings,
    getRareVisitData,
    setRareVisitData,
    getShouldFilterByRareVisits,
    setShouldFilterByRareVisits,
    getBuildingAppDefinitions,
} = createAccessors('places-explore-app-store', getStore);

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const setRecentBuildings = mutatorAction(
    'SET_RECENT_BUILDINGS',
    (recentBuildings: string[]) => {
        store.recentBuildings = recentBuildings;
    }
);

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const addRareVisitData = mutatorAction(
    'ADD_RARE_VISIT_DATA',
    (email: UserEmailAddress, buildingIds: string[]) => {
        store.rareVisitData.set(email, buildingIds);
    }
);

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const setBuildingAppDefinitions = mutatorAction(
    'SET_BUILDING_APP_DEFINITIONS',
    (buildingId: string, apps: AppDefinition[]) => {
        store.buildingAppDefinitions.set(buildingId, apps);
    }
);
