import type { LogData } from 'owa-logging-utils';
import { logMailComposeSaveSend } from 'owa-mail-compose-logging';
import { emitSyncEvent } from 'owa-offline-sync-diagnostics';

export function logComposeOfflineData(
    eventName: string,
    logData: LogData,
    editorId: string | undefined,
    shouldSyncLog: boolean = true
) {
    logMailComposeSaveSend(eventName, editorId, logData);
    if (shouldSyncLog) {
        emitSyncEvent('MailComposeOfflineAction', { eventName, ...logData });
    }
}
