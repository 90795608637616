import { lazyHybridspaceWorkWeekCalendarSurfaceModule } from 'hybridspace-calendar';
import { lazyWorkplanModule } from 'hybridspace-home';
import { getWorkplanFWH } from 'hybridspace-home-app-store';
import { logGreyError, logUsage } from 'owa-analytics';
import { createDateRange } from 'owa-datetime-utils';
import { loadCollaboratorsLocations, loadFWHAsync, loadPlaces } from 'places-explore-operations';
import { placesFwkRootComponentMountedAction, placesLoaderAction } from 'places-fwk-actions';
import { loadPlacesPeopleModule } from 'places-people';
import { getCurrentDate, getCurrentDateRange } from 'places-user-session-stores';

import type { LoaderFunction } from 'react-router-dom';

// Loader for the work-plan route
// (/people) if user is Premium
// (/) if user is Core
export const WorkplanLoader: LoaderFunction = async ({ request }) => {
    placesLoaderAction('PlacesWorkplanLoader');

    // When coming from a prefetcher (we are preloading the people tab) we should not throw an error
    let shouldNotThrowError = false;
    try {
        const url = new URL(request.url);
        shouldNotThrowError = url.searchParams.get('throwError')?.toLowerCase() === 'true';
    } catch (e) {
        logUsage('PlacesWorkplanLoaderFetchURLError', { error: e });
    }

    // starting agenda panel marker here as it is the first function before react render starts.
    placesFwkRootComponentMountedAction('PlacesAgendaPanelRoot');

    const fwh = getWorkplanFWH();
    // Skip critical data
    if (fwh != undefined) {
        return null;
    }

    const currentDateRange = getCurrentDateRange();

    //non critical data
    loadPlaces();
    loadCollaboratorsLocations(currentDateRange);

    // Define critical data: workplan module, and FWH for the week
    const fetchFWH = loadFWHAsync(getCurrentDateRange());
    const fetchTodayFWH = loadFWHAsync(createDateRange(getCurrentDate(), 1));
    const workplanModule = lazyWorkplanModule.import();
    const calendarModule = lazyHybridspaceWorkWeekCalendarSurfaceModule.import();

    const criticalData: Promise<any>[] = [
        workplanModule,
        fetchFWH,
        fetchTodayFWH,
        calendarModule,
        loadPlacesPeopleModule(),
    ];

    try {
        await Promise.all(criticalData);
        return null;
    } catch (error) {
        logGreyError('WorkplanLoader', error);
        // If coming from prefetcher, we will not throw an error, see PlacesRootRouteOutletContainer.tsx
        if (!shouldNotThrowError) {
            return null;
        }
        throw error;
    }
};
