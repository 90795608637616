import { getAttachmentSettingsDatabase } from '../database';
import { SETTINGS_ROOT_DIRECTORY_HANDLE } from '../schema';

let rootDirectoryHandle: FileSystemDirectoryHandle | undefined = undefined;

export async function getDirectoryHandleSetting() {
    if (rootDirectoryHandle) {
        return rootDirectoryHandle;
    }

    const database = getAttachmentSettingsDatabase();
    const handle = await database.settings.get(SETTINGS_ROOT_DIRECTORY_HANDLE);
    if (handle) {
        rootDirectoryHandle = handle.data;
        if (!handle?.creationTime) {
            await database.settings.put({
                name: SETTINGS_ROOT_DIRECTORY_HANDLE,
                data: handle.data,
                creationTime: new Date(),
            });
        }
    }

    return rootDirectoryHandle;
}

export function storeDirectoryHandleSetting(handle: FileSystemDirectoryHandle) {
    const database = getAttachmentSettingsDatabase();
    return database.settings.put({
        name: SETTINGS_ROOT_DIRECTORY_HANDLE,
        data: handle,
        creationTime: new Date(),
    });
}
/**
 * This function is used to check if the directory handle is cached or not,
 * make sure getDirectoryHandleSetting is called before calling this function
 */
export function isDirectoryHandleAvailable() {
    return !!rootDirectoryHandle;
}

export async function clearDirectoryHandleSetting(logUsageCallback?: (days: number) => void) {
    const database = getAttachmentSettingsDatabase();
    rootDirectoryHandle = undefined;
    if (logUsageCallback) {
        const handle = await database.settings.get(SETTINGS_ROOT_DIRECTORY_HANDLE);
        if (handle?.creationTime) {
            const now = new Date();
            const days = Math.floor(
                (now.getTime() - handle.creationTime.getTime()) / (1000 * 60 * 60 * 24)
            );
            logUsageCallback(days);
        }
    }
    return database.settings.delete(SETTINGS_ROOT_DIRECTORY_HANDLE);
}
