import { StorageStrategyFactory, StorageType } from 'owa-attachment-storage';
import { logGreyError } from 'owa-analytics';
import { isDirectoryHandleExpired } from 'owa-attachment-settings';

export const getAttachmentById = async (
    attachmentId: string,
    directoryHandle: FileSystemDirectoryHandle | undefined,
    persistenceId: string | null
) => {
    if (!directoryHandle) {
        throw new Error('Directory handle undefined on getAttachmentById');
    }
    if (await isDirectoryHandleExpired(directoryHandle)) {
        throw new Error('Directory handle is expired on getAttachmentById');
    }
    const factory = new StorageStrategyFactory();
    const storageStrategy = factory.createStorageStrategy(StorageType.FileSystem, directoryHandle);
    try {
        const attachment = await storageStrategy.getAttachment('', {
            attachmentId,
            persistenceId,
        });
        return attachment;
    } catch (e) {
        const additionalInfo = e.additionalInfo;
        logGreyError('CnexErrorAttachmentGetFailed', e, {
            additionalInfo,
        });
        throw e;
    }
};
