import { getTypeOfDirectory } from './getTypeOfDirectory';

declare global {
    interface FileSystemDirectoryHandle {
        queryPermission: (descriptor: { mode: 'readwrite' | 'read' }) => PermissionState;
    }
}

export async function isDirectoryHandleExpired(
    directoryHandle: FileSystemDirectoryHandle | undefined,
    onErrorCallback?: (e: Error, reason: string, name: string) => void
): Promise<boolean> {
    if (!directoryHandle) {
        return true;
    }

    try {
        const hasPermission = await hasGrantedPermission(directoryHandle);
        if (hasPermission) {
            return false;
        } else {
            throw new Error('Directory handle does not have permission');
        }
    } catch (e) {
        onErrorCallback?.(e, 'DirectoryHandleExpired', getTypeOfDirectory(directoryHandle.name));
    }
    return true;
}

async function hasGrantedPermission(fileHandle: FileSystemDirectoryHandle): Promise<boolean> {
    const options: {
        mode: 'readwrite';
    } = { mode: 'readwrite' };

    const permission = await fileHandle.queryPermission(options);

    return permission === 'granted';
}
