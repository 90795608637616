import { trace } from 'owa-trace';

/**
 * A typical OWA JWT token payload is:
 *  {
        appctx: "",
        appctxsender: "",
        aud: "",
        controls: "",
        controls_auds: "",
        exp: 1679338566,
        happ: "owa",
        iss: "",
        issring: "SDFv2",
        nbf: 1679337966,
        origin: "https://outlook-sdf.office.com",
        signin_state: "",
        uc: "",
        ver: "Exchange.Callback.V1"
    }

    The "exp" (Expiration Time) Claim specifies that the token MUST NOT be accepted for processing after the specified timestamp.
    The timestamps in JWT are UNIX timestamps counting from 01.01.1970 00:00 UTC: https://www.rfc-editor.org/rfc/rfc7519#section-4.1.4
 */
export function isAttachmentDownloadTokenExpired(token: string): boolean {
    try {
        const obj = parseJwt(token);
        const currentTime = Date.now();
        return currentTime > obj.exp * 1000;
    } catch (error) {
        trace.warn('Parse JWT token error: ' + error);
    }

    return true;
}

function parseJwt(token: string) {
    const tokenParts = token.split('.');
    if (tokenParts.length !== 3) {
        throw new Error('Invalid JWT token');
    }

    const base64Url = tokenParts[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
            .join('')
    );

    return JSON.parse(jsonPayload);
}
