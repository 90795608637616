import type { AppDatabase } from 'owa-offline-database';
import { getMessageBodies } from './getMessageBodies';
import type { MailboxInfo } from 'owa-client-types';
import { isConsumer, isPremiumConsumer, isBusiness, isEdu } from 'owa-session-store';
import { isAttachmentDownloadTokenExpired } from 'owa-files-url';

interface MessageBodyLogs {
    existMessageBody: boolean;
    indexOfAttachment: number | undefined;
    totalAttachments: number | undefined;
    isDraft: boolean | undefined;
    diffInDays: number | undefined;
    hasRM: boolean | undefined;
    attachmentType: string | undefined;
    contentType: string | undefined;
    attachmentExtension: string | undefined;
    isAttachmentDownloadTokenExpired: boolean | undefined;
    typeOfAccount: string;
}

export async function getMessageBodyLogs<
    TRequest extends {
        messageBodyId: string;
        attachmentId: string;
    }
>(
    database: AppDatabase,
    item: TRequest,
    mailboxInfo: MailboxInfo,
    url: URL
): Promise<MessageBodyLogs> {
    /* eslint-disable-next-line owa-custom-rules/require-undefined-parameter -- (https://aka.ms/OWALintWiki)
     * isConsumer should specify the account using MailboxInfo. The user identity should not be used for identifying the account.
     *	> The parameter smtpAddress must be undefined. */
    const typeOfAccount = isConsumer(mailboxInfo.mailboxSmtpAddress)
        ? 'consumer'
        : isPremiumConsumer(mailboxInfo)
        ? 'premium'
        : isBusiness(mailboxInfo)
        ? 'business'
        : isEdu(mailboxInfo)
        ? 'edu'
        : 'unknown';
    const messageBodies = await getMessageBodies(database, [item.messageBodyId]);
    const messageBody = messageBodies[0];

    if (!messageBody) {
        return {
            existMessageBody: false,
            indexOfAttachment: undefined,
            totalAttachments: undefined,
            isDraft: undefined,
            diffInDays: undefined,
            hasRM: undefined,
            attachmentType: undefined,
            contentType: undefined,
            attachmentExtension: undefined,
            isAttachmentDownloadTokenExpired: undefined,
            typeOfAccount,
        };
    }

    let diffInDays = -1;
    if (messageBody.DateTimeReceived) {
        const date1 = new Date(messageBody.DateTimeReceived as string);
        const date2 = new Date();

        const diffInTime = date2.getTime() - date1.getTime();
        diffInDays = Math.floor(diffInTime / (1000 * 3600 * 24));
    }

    const indexOfAttachment = messageBody?.Attachments?.findIndex(
        element => element?.AttachmentId?.Id === item.attachmentId
    );
    const attachment =
        indexOfAttachment !== undefined && indexOfAttachment !== -1
            ? messageBody?.Attachments?.[indexOfAttachment]
            : undefined;
    const totalAttachments = messageBody?.Attachments?.length || -1;

    let isTokenExpired: boolean | undefined = undefined;
    if (url.searchParams.has('token')) {
        const token = url.searchParams.get('token') as string;
        isTokenExpired = isAttachmentDownloadTokenExpired(token);
    }

    return {
        existMessageBody: !!messageBody,
        indexOfAttachment,
        totalAttachments,
        isDraft: messageBody.IsDraft || false,
        diffInDays,
        hasRM: !!messageBody.RightsManagementLicenseData,
        attachmentType: 'full',
        contentType: attachment?.ContentType || undefined,
        attachmentExtension: attachment?.Name?.split('.')?.pop() || undefined,
        isAttachmentDownloadTokenExpired: !!isTokenExpired,
        typeOfAccount,
    };
}
