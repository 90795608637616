import type { AttachmentIdAndPersistenceId } from './getAttachmentIdAndPersistenceId';
import { getDatabase } from '../database';

export async function deleteAttachmentFromDatabase(
    attachmentIdAndPersistenceId: AttachmentIdAndPersistenceId
) {
    const attachmentDatabase = await getDatabase(attachmentIdAndPersistenceId.persistenceId);
    const attachment = await attachmentDatabase.attachments.delete(
        attachmentIdAndPersistenceId.attachmentId
    );
    return attachment;
}
