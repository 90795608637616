import type { DexieKey } from 'owa-database-schema';

export interface AttachmentTableEntry {
    attachmentId: string;
    fileName: string;
    fileSize: number; // the file size in bytes
    timeCached?: Date; // when the attachment was stored
    messageBodyId?: string; //used for the cleanup of the message bodies table
    expiryTime?: Date; //used for the cleanup of on-demand synced attachments
}
// Properties tha can be updated in the attachment table
export type AttachmentTableMetaData = Partial<
    Omit<AttachmentTableEntry, 'attachmentId' | 'fileSize' | 'timeCached'>
>;

export const ATTACHMENTS_PRIMARY_KEY: DexieKey<AttachmentTableEntry> = 'attachmentId';
export const ATTACHMENTS_TIME_CACHED_KEY: DexieKey<AttachmentTableEntry> = 'timeCached';
export const MESSAGE_BODY_ID_KEY: DexieKey<AttachmentTableEntry> = 'messageBodyId';

export const attachmentsSchema = {
    attachments: `${ATTACHMENTS_PRIMARY_KEY},${ATTACHMENTS_TIME_CACHED_KEY},${MESSAGE_BODY_ID_KEY}`,
};
