import type { Table } from 'dexie';
import type { DexieKey } from 'owa-database-schema';
import { compositeDexieKey } from 'owa-database-schema';
import type { PGALRecord } from './PGALRecord';

export type PGALSortMapIntl = {
    discriminant: DexieKey<PGALRecord>;
    sortField: DexieKey<PGALRecord>;
    secondarySortFirstLast: DexieKey<PGALRecord>;
    secondarySortLastFirst: DexieKey<PGALRecord>;
};

export const PGAL_TABLE_NAME = 'pgal';
export type PGALTable = Table<PGALRecord, string>;

export const PGAL_CONTACTID_KEY: DexieKey<PGALRecord> = 'contactId';
export const PGAL_OLS_PERSONAID_KEY: DexieKey<PGALRecord> = 'outlookServicesPersonaId';
export const PGAL_TAGS_KEY: DexieKey<PGALRecord> = '$rawTags';
export const PGAL_EMAILS_KEY: DexieKey<PGALRecord> = '$rawEmails';
export const PGAL_PHONES_KEY: DexieKey<PGALRecord> = '$rawPhones';
export const PGAL_FULLNAME_KEY: DexieKey<PGALRecord> = '$rawFullNames';

export const PGAL_FIRST_NAME_SORT_MAP_INTL: PGALSortMapIntl = {
    discriminant: 'indexableIsContact',
    sortField: '$defaultFirstName',
    secondarySortFirstLast: '$defaultDisplayNameFirstLast',
    secondarySortLastFirst: '$defaultDisplayNameLastFirst',
};
export const PGAL_FIRST_NAME_SORT_KEY_INTL = compositeDexieKey<PGALRecord>([
    PGAL_FIRST_NAME_SORT_MAP_INTL.discriminant,
    PGAL_FIRST_NAME_SORT_MAP_INTL.sortField,
    PGAL_FIRST_NAME_SORT_MAP_INTL.secondarySortFirstLast,
    PGAL_FIRST_NAME_SORT_MAP_INTL.secondarySortLastFirst,
]);

export const PGAL_LAST_NAME_SORT_MAP_INTL: PGALSortMapIntl = {
    discriminant: 'indexableIsContact',
    sortField: '$defaultLastName',
    secondarySortFirstLast: '$defaultDisplayNameFirstLast',
    secondarySortLastFirst: '$defaultDisplayNameLastFirst',
};
export const PGAL_LAST_NAME_SORT_KEY_INTL = compositeDexieKey<PGALRecord>([
    PGAL_LAST_NAME_SORT_MAP_INTL.discriminant,
    PGAL_LAST_NAME_SORT_MAP_INTL.sortField,
    PGAL_LAST_NAME_SORT_MAP_INTL.secondarySortFirstLast,
    PGAL_LAST_NAME_SORT_MAP_INTL.secondarySortLastFirst,
]);

export const PGAL_CREATED_DATE_SORT_MAP_INTL: PGALSortMapIntl = {
    discriminant: 'indexableIsContact',
    sortField: '$primaryCreatedDateTime',
    secondarySortFirstLast: '$defaultDisplayNameFirstLast',
    secondarySortLastFirst: '$defaultDisplayNameLastFirst',
};
export const PGAL_CREATED_DATE_SORT_KEY_INTL = compositeDexieKey<PGALRecord>([
    PGAL_CREATED_DATE_SORT_MAP_INTL.discriminant,
    PGAL_CREATED_DATE_SORT_MAP_INTL.sortField,
    PGAL_CREATED_DATE_SORT_MAP_INTL.secondarySortFirstLast,
    PGAL_CREATED_DATE_SORT_MAP_INTL.secondarySortLastFirst,
]);

export const PGAL_TAGS_SORT_MAP_INTL: PGALSortMapIntl = {
    discriminant: 'indexableIsContact',
    sortField: '$rawTagsComposite',
    secondarySortFirstLast: '$defaultDisplayNameFirstLast',
    secondarySortLastFirst: '$defaultDisplayNameLastFirst',
};
export const PGAL_TAGS_SORT_KEY_INTL = compositeDexieKey<PGALRecord>([
    PGAL_TAGS_SORT_MAP_INTL.discriminant,
    PGAL_TAGS_SORT_MAP_INTL.sortField,
    PGAL_TAGS_SORT_MAP_INTL.secondarySortFirstLast,
    PGAL_TAGS_SORT_MAP_INTL.secondarySortLastFirst,
]);

export const PGAL_EMAIL_ADDRESS_SORT_MAP_INTL: PGALSortMapIntl = {
    discriminant: 'indexableIsContact',
    sortField: '$defaultEmailAddress',
    secondarySortFirstLast: '$defaultDisplayNameFirstLast',
    secondarySortLastFirst: '$defaultDisplayNameLastFirst',
};
export const PGAL_EMAIL_ADDRESS_SORT_KEY_INTL = compositeDexieKey<PGALRecord>([
    PGAL_EMAIL_ADDRESS_SORT_MAP_INTL.discriminant,
    PGAL_EMAIL_ADDRESS_SORT_MAP_INTL.sortField,
    PGAL_EMAIL_ADDRESS_SORT_MAP_INTL.secondarySortFirstLast,
    PGAL_EMAIL_ADDRESS_SORT_MAP_INTL.secondarySortLastFirst,
]);

export const PGAL_COMPANY_SORT_MAP_INTL: PGALSortMapIntl = {
    discriminant: 'indexableIsContact',
    sortField: '$defaultCompany',
    secondarySortFirstLast: '$defaultDisplayNameFirstLast',
    secondarySortLastFirst: '$defaultDisplayNameLastFirst',
};
export const PGAL_COMPANY_SORT_KEY_INTL = compositeDexieKey<PGALRecord>([
    PGAL_COMPANY_SORT_MAP_INTL.discriminant,
    PGAL_COMPANY_SORT_MAP_INTL.sortField,
    PGAL_COMPANY_SORT_MAP_INTL.secondarySortFirstLast,
    PGAL_COMPANY_SORT_MAP_INTL.secondarySortLastFirst,
]);

export const PGAL_LOCATION_SORT_MAP_INTL: PGALSortMapIntl = {
    discriminant: 'indexableIsContact',
    sortField: '$defaultOfficeLocation',
    secondarySortFirstLast: '$defaultDisplayNameFirstLast',
    secondarySortLastFirst: '$defaultDisplayNameLastFirst',
};
export const PGAL_LOCATION_SORT_KEY_INTL = compositeDexieKey<PGALRecord>([
    PGAL_LOCATION_SORT_MAP_INTL.discriminant,
    PGAL_LOCATION_SORT_MAP_INTL.sortField,
    PGAL_LOCATION_SORT_MAP_INTL.secondarySortFirstLast,
    PGAL_LOCATION_SORT_MAP_INTL.secondarySortLastFirst,
]);

export const PGAL_IS_CONTACT_KEY: DexieKey<PGALRecord> = 'indexableIsContact';

export const pgalSchema = {
    [PGAL_TABLE_NAME]: [
        PGAL_CONTACTID_KEY,
        PGAL_OLS_PERSONAID_KEY,
        '*' + PGAL_TAGS_KEY,
        '*' + PGAL_EMAILS_KEY,
        '*' + PGAL_PHONES_KEY,
        '*' + PGAL_FULLNAME_KEY,
        PGAL_FIRST_NAME_SORT_KEY_INTL,
        PGAL_LAST_NAME_SORT_KEY_INTL,
        PGAL_CREATED_DATE_SORT_KEY_INTL,
        PGAL_TAGS_SORT_KEY_INTL,
        PGAL_EMAIL_ADDRESS_SORT_KEY_INTL,
        PGAL_COMPANY_SORT_KEY_INTL,
        PGAL_LOCATION_SORT_KEY_INTL,
        PGAL_IS_CONTACT_KEY,
    ].join(','),
};

// Not used in the pgal schema/table
export const PGAL_RELEVANCE_SORT_MAP_INTL: PGALSortMapIntl = {
    discriminant: 'indexableIsContact',
    sortField: '$relevanceRank',
    secondarySortFirstLast: '$defaultDisplayNameFirstLast',
    secondarySortLastFirst: '$defaultDisplayNameLastFirst',
};
export const PGAL_RELEVANCE_SORT_KEY_INTL = compositeDexieKey<PGALRecord>([
    PGAL_LOCATION_SORT_MAP_INTL.discriminant,
    PGAL_LOCATION_SORT_MAP_INTL.sortField,
    PGAL_LOCATION_SORT_MAP_INTL.secondarySortFirstLast,
    PGAL_LOCATION_SORT_MAP_INTL.secondarySortLastFirst,
]);
