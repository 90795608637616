/* eslint-disable-next-line @typescript-eslint/no-restricted-imports -- (https://aka.ms/OWALintWiki)
 * BASELINE. Do not copy and paste!
 *	> 'dexie' import is restricted from being used. Importing from dexie is allowed but needs an additional approver */
import Dexie from 'dexie';

export async function deleteDatabase(name: string) {
    if (await Dexie.exists(name)) {
        Dexie.delete(name);
    }
}
