import type { LicensingMailboxInfo, PolicyStateTypeEnum } from 'owa-account-source-list-types';
import { getAllOfficeIdentities } from 'owa-account-source-list-store/lib/selectors/getAllOfficeIdentities';
import {
    getIdentityInfoForLicensingMailboxInfo,
    getIdentityKeyForIdentityInfo,
} from 'owa-auth-types';

/**
 * Lookup the policy state for the licensing mailbox in the store
 *
 * Note: this function checks data in the owa-account-source-list-store which
 * is replicated from the main thread to the work thread and so the check may
 * be done on either the main thread or the worker thread.
 * @param mailboxInfo Specifies the licensing mailbox to lookup
 * @returns The policy state for the licensing mailbox or undefined if no match can be found
 */
export function getLicensingMailboxPolicyState(
    mailboxInfo: LicensingMailboxInfo
): PolicyStateTypeEnum | undefined {
    const identityInfo = getIdentityInfoForLicensingMailboxInfo(mailboxInfo);
    const identityKey = getIdentityKeyForIdentityInfo(identityInfo);

    // We are going to run on the worker thread so we need to get just the mailboxes
    // from the store so we don't pull in the pending account data.
    return getAllOfficeIdentities().filter(current => {
        const currentInfo = getIdentityInfoForLicensingMailboxInfo(current);
        const currentKey = getIdentityKeyForIdentityInfo(currentInfo);
        return currentKey === identityKey;
    })[0]?.policyState;
}
