import { type TraceErrorObject } from 'owa-trace';

export type AttachmentStorageError =
    | 'NetworkError'
    | 'FileSystemError'
    | 'DatabaseError'
    | 'GeneralError';

export interface AttachmentStorageErrorObject {
    notFoundError: TraceErrorObject;
    undefinedObjectError: TraceErrorObject;
    noPermissionError?: TraceErrorObject;
    notLocallyAvailableError?: TraceErrorObject;
    securityError?: TraceErrorObject;
}

const rootDirectoryHandleNotFoundError = createNewAttachmentStorageError(
    new Error('Root directory handle not found in File System'),
    { errorType: 'FileSystemError', shouldRestart: true }
);
const rootDirectoryHandleNoPermissionError = createNewAttachmentStorageError(
    new Error('No permission to read and write the root directory handle'),
    { errorType: 'FileSystemError', shouldRestart: true }
);
const rootDirectoryHandleEmptyError = createNewAttachmentStorageError(
    new Error('Cannot get the root directory handle'),
    { errorType: 'FileSystemError', shouldRestart: true }
);
const rootDirectoryHandleNotAvailableError = createNewAttachmentStorageError(
    new Error('Root directory handle not locally available'),
    { errorType: 'FileSystemError', shouldRestart: true }
);
const rootDirectoryHandleSecurityPolicyError = createNewAttachmentStorageError(
    new Error('Operation failed due to security policy on the root directory handle'),
    { errorType: 'FileSystemError', shouldRestart: true }
);

const persistedDirectoryHandleNotFoundError = createNewAttachmentStorageError(
    new Error('Persisted directory handle not found in File System'),
    { errorType: 'FileSystemError', shouldRestart: false }
);
const persistedDirectoryHandleEmptyError = createNewAttachmentStorageError(
    new Error('Cannot get the persisted directory handle'),
    { errorType: 'FileSystemError', shouldRestart: false }
);
const persistedDirectoryHandleSecurityPolicyError = createNewAttachmentStorageError(
    new Error('Operation failed due to security policy on the persisted directory handle'),
    { errorType: 'FileSystemError', shouldRestart: false }
);
const persistedDirectoryHandleNoPermissionError = createNewAttachmentStorageError(
    new Error('No permission to get the persisted directory handle'),
    { errorType: 'FileSystemError', shouldRestart: false }
);

const attachmentDirectoryHandleNotFoundError = createNewAttachmentStorageError(
    new Error('Attachment directory handle not found in File System'),
    { errorType: 'FileSystemError', shouldRestart: false }
);
const attachmentDirectoryHandleEmptyError = createNewAttachmentStorageError(
    new Error('Cannot get the attachment directory handle'),
    { errorType: 'FileSystemError', shouldRestart: false }
);
const attachmentDirectoryHandleSecurityPolicyError = createNewAttachmentStorageError(
    new Error('Operation failed due to security policy on the attachment directory handle'),
    { errorType: 'FileSystemError', shouldRestart: false }
);
const attachmentDirectoryHandleNoPermissionError = createNewAttachmentStorageError(
    new Error('No permission to get the attachment directory handle'),
    { errorType: 'FileSystemError', shouldRestart: false }
);

const fileHandleNotFoundError = createNewAttachmentStorageError(
    new Error('File handle not found in File System'),
    {
        errorType: 'FileSystemError',
        shouldRestart: false,
    }
);
const fileHandleEmptyError = createNewAttachmentStorageError(
    new Error('Cannot get the file handle'),
    {
        errorType: 'FileSystemError',
        shouldRestart: false,
    }
);
const fileHandleSecurityPolicyError = createNewAttachmentStorageError(
    new Error('Operation failed due to security policy on the file handle'),
    { errorType: 'FileSystemError', shouldRestart: false }
);

export const rootDirectoryErrors: AttachmentStorageErrorObject = {
    notFoundError: rootDirectoryHandleNotFoundError,
    undefinedObjectError: rootDirectoryHandleEmptyError,
    noPermissionError: rootDirectoryHandleNoPermissionError,
    notLocallyAvailableError: rootDirectoryHandleNotAvailableError,
    securityError: rootDirectoryHandleSecurityPolicyError,
};

export const persistedDirectoryErrors: AttachmentStorageErrorObject = {
    notFoundError: persistedDirectoryHandleNotFoundError,
    undefinedObjectError: persistedDirectoryHandleEmptyError,
    noPermissionError: persistedDirectoryHandleNoPermissionError,
    securityError: persistedDirectoryHandleSecurityPolicyError,
};

export const attachmentDirectoryErrors: AttachmentStorageErrorObject = {
    notFoundError: attachmentDirectoryHandleNotFoundError,
    undefinedObjectError: attachmentDirectoryHandleEmptyError,
    noPermissionError: attachmentDirectoryHandleNoPermissionError,
    securityError: attachmentDirectoryHandleSecurityPolicyError,
};

export const fileHandleErrors: AttachmentStorageErrorObject = {
    notFoundError: fileHandleNotFoundError,
    undefinedObjectError: fileHandleEmptyError,
    securityError: fileHandleSecurityPolicyError,
};

export function createNewAttachmentStorageError(
    error: Error,
    additionalInfo: {
        shouldRestart: boolean;
        originalError?: string;
        errorType: AttachmentStorageError;
    }
) {
    (error as TraceErrorObject).additionalInfo = { ...additionalInfo };
    return error;
}
