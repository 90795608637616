import { placesAnalyticsAppLoadAction } from 'places-fwk-actions';
import type { TraceErrorObject } from 'owa-trace';

type Scenario = Parameters<typeof placesAnalyticsAppLoadAction>[0]['scenario'];
type CustomData = Parameters<typeof placesAnalyticsAppLoadAction>[0]['customData'];
type Source = 'network' | 'cache';

/**
 * Logs the failure of an API call during app load.
 * @param scenario - The scenario for which the API call failed
 * @param customMessage - A custom message to include in the log
 * @param error - The error object that caused the API call to fail
 * @returns void
 */
export function logAppLoadApiFailure(scenario: Scenario, customMessage: string, error: Error) {
    if (!error) {
        return;
    }
    const errorObject: TraceErrorObject = new Error(
        'Failed to fetch data from the network/cache. See CustomData.Diagnostics for more details.'
    ) as TraceErrorObject;
    errorObject.additionalInfo = {
        customMessage,
        diagnosticMessage: error.message,
        stack: error.stack,
    };
    placesAnalyticsAppLoadAction({
        scenario,
        errorObject,
    });
}

/**
 * Logs the success of an API call during app load.
 * @param scenario - The scenario for which the API call succeeded
 * @param source - The source from which the data was fetched (network or cache)
 * @param customData - Any additional data to include in the log
 * @returns void
 */
export function logAppLoadApiSuccess(
    scenario: Scenario,
    source: Source,
    customData: CustomData = {}
) {
    const srcKey = `Source_${scenario}`;
    placesAnalyticsAppLoadAction({
        scenario,
        customData: {
            [srcKey]: source,
            ...customData,
        },
    });
}
