import type { Features } from 'owa-graph-schema';
import { userFeatureAccessTable, type UserFeatureAccess } from '../database';
import { logGreyError } from 'owa-analytics';
import { getCurrentTimestamp } from '../utils';

/**
 * Upserts (inserts or updates) user feature access data into the IndexedDB cache.
 */
export const upsertUserFeatureAccessToCache = async (
    ownerId: string,
    networkFeatureAccess: (Features | null)[]
): Promise<void> => {
    const cacheEntry: UserFeatureAccess = {
        ownerId,
        featureAccess: networkFeatureAccess,
        timestamp: getCurrentTimestamp(),
    };
    userFeatureAccessTable.put(cacheEntry).catch(error => {
        logGreyError('PlacesAnalytics_UpsertUserFeatureAccessToCache_Error', error);
    });
};
