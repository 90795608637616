import { getUserExternalDirectoryId } from 'accelerator-aad/lib/getUserExternalDirectoryId';
import { ObservableMap } from 'mobx';
import { getItem, setItem, removeItem, type LocalStorageKeys } from 'owa-local-storage';
import { createAccessors } from 'owa-viewstate-store';
import { createStore, mutatorAction } from 'satcheljs';

import type { Place } from 'owa-graph-schema';

/**
 * This function returns the key for the tenantHasBuildings property in local storage
 */
export function getTenantHasBuildingsKey(): LocalStorageKeys {
    const ownerId = getUserExternalDirectoryId();
    return `${ownerId}-TENANT_HAS_BUILDINGS`;
}

const getStore = createStore('places-places-store', {
    places: new ObservableMap<string, Place>(), // Map of all the buildings as the Place object, key is building id
    tenantHasBuildings: undefined as boolean | undefined,
    placesServiceCallsCompleted: new ObservableMap<string, boolean>(), // Key can either be buildingId or "all" for all places in the tenant
});

const store = getStore();

const { getPlaces, getTenantHasBuildings, setTenantHasBuildings, getPlacesServiceCallsCompleted } =
    createAccessors('places-places-store', getStore);

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const setPlace = mutatorAction('setPlace', (buildingId: string, place: Place) => {
    store.places.set(buildingId, place);
});

export function setTenantHasBuildingsCacheValue() {
    const tenantHasBuildingsCacheValue = getItem(window, getTenantHasBuildingsKey());
    if (tenantHasBuildingsCacheValue) {
        try {
            const tenantHasBuildings = JSON.parse(tenantHasBuildingsCacheValue);
            if (typeof tenantHasBuildings === 'boolean') {
                setTenantHasBuildings(tenantHasBuildings);
            } else {
                throw new Error('Invalid value');
            }
        } catch (e) {
            removeItem(window, getTenantHasBuildingsKey());
        }
    }
}

export function setTenantHasBuildingsStoreAndCache(value: boolean) {
    setTenantHasBuildings(value);
    setItem(window, getTenantHasBuildingsKey(), JSON.stringify(value));
}

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const addServiceCallsCompleted = mutatorAction(
    'addServiceCallsCompleted',
    (placeServiceCallType: string, isSuccess: boolean) => {
        const serviceCalls = getPlacesServiceCallsCompleted();
        serviceCalls.set(placeServiceCallType, isSuccess);
        getStore().placesServiceCallsCompleted = serviceCalls;
    }
);

export { getPlaces, getTenantHasBuildings, getPlacesServiceCallsCompleted };
