/* eslint-disable-next-line @typescript-eslint/no-restricted-imports -- (https://aka.ms/OWALintWiki)
 * BASELINE. Do not copy and paste!
 *	> 'dexie' import is restricted from being used. Importing from dexie is allowed but needs an additional approver */
import Dexie, { type PromiseExtended } from 'dexie';
import type MailboxInfo from 'owa-client-types/lib/MailboxInfo';
import { deleteDatabase } from './utils/deleteDatabase';

interface DatabaseConstructor<T> {
    new (name: string, mailboxInfo?: MailboxInfo): T;
}

export class DatabaseManager<T extends Dexie> {
    private databaseDeleteListeners: Array<(database: T) => void> = [];
    public databases = new Map<string, PromiseExtended<T>>();
    constructor(private namePrefix: string, private Database: DatabaseConstructor<T>) {}

    getDatabase(mailboxInfo?: MailboxInfo | null, databaseId: string = ''): PromiseExtended<T> {
        let database = this.databases.get(databaseId);
        if (!database) {
            database = this.initializeDatabase(databaseId, mailboxInfo ?? undefined);
        }

        return database;
    }

    async deleteDatabase(databaseId: string = ''): Promise<void> {
        const databaseName = this.getDatabaseName(databaseId);
        const databasePromise = this.databases.get(databaseId);
        if (databasePromise) {
            const database = await databasePromise;
            database.close({ disableAutoOpen: true });
            for (const listener of this.databaseDeleteListeners) {
                listener(database);
            }
        }
        this.databases.delete(databaseId);
        return deleteDatabase(databaseName);
    }

    async getPersistedDatabaseIds(includeDefault: boolean = false): Promise<string[]> {
        try {
            const databaseNames = await Dexie.getDatabaseNames();
            return databaseNames
                .filter(
                    name =>
                        (includeDefault ? true : name !== this.namePrefix) &&
                        name.startsWith(this.namePrefix)
                )
                .map(name =>
                    name === this.namePrefix ? '' : name.replace(`${this.namePrefix}-`, '')
                );
        } catch {
            return [];
        }
    }

    registerDatabaseDeleteListener(listener: (database: T) => void) {
        this.databaseDeleteListeners.push(listener);
    }

    private initializeDatabase(
        databaseId: string = '',
        mailboxInfo?: MailboxInfo
    ): PromiseExtended<T> {
        if (databaseId) {
            // we are in a multi-account environment and need to migrate from old DB name
            deleteDatabase(this.namePrefix);
        }

        const database = new this.Database(this.getDatabaseName(databaseId), mailboxInfo);
        const openPromise = database.open() as PromiseExtended<T>;
        this.databases.set(databaseId, openPromise);

        return openPromise;
    }

    private getDatabaseName(databaseId?: string) {
        return databaseId ? `${this.namePrefix}-${databaseId}` : this.namePrefix;
    }
}
