import { LazyAction, LazyModule } from 'owa-bundling-light';

const lazyModule = new LazyModule(() => import(/* webpackChunkName: "DataWorker" */ './lazyIndex'));

export const lazyExecute = new LazyAction(lazyModule, m => m.execute);
export const lazyUnsubscribe = new LazyAction(lazyModule, m => m.unsubscribe);
export const lazySetNotificationCallbacks = new LazyAction(
    lazyModule,
    m => m.setNotificationCallbacks
);
export const lazyNotifySubscription = new LazyAction(lazyModule, m => m.notifySubscription);
export const lazyInitializeOfflineSync = new LazyAction(lazyModule, m => m.initializeOfflineSync);
export const lazyFlushSyncLogs = new LazyAction(lazyModule, m => m.flushSyncLogs);
export const lazyInitializeApplicationSettings = new LazyAction(
    lazyModule,
    m => m.initializeApplicationSettings
);
export const lazyInitializeDateTime = new LazyAction(lazyModule, m => m.initializeDateTime);
export const lazyInitializeFeatureFlags = new LazyAction(lazyModule, m => m.initializeFeatureFlags);
export const lazyInvokeWin32GqlRequestCallback = new LazyAction(
    lazyModule,
    m => m.invokeWin32GqlRequestCallback
);

export const lazyGetOfflineTimeZoneOffsets = new LazyAction(
    lazyModule,
    m => m.getOfflineTimeZoneOffsets
);

export const lazyGetOfflineTimeZones = new LazyAction(lazyModule, m => m.getOfflineTimeZones);
