import { getValidFileSystemName } from './getValidFileSystemName';

const PersistedFolderNamePrefix = 'ooa';

export function getPersistedFolderName(persistenceId: string | null): string {
    if (persistenceId) {
        return `${PersistedFolderNamePrefix}-${getValidFileSystemName(persistenceId)}`;
    } else {
        return PersistedFolderNamePrefix;
    }
}

export function getPersistedFolder(
    directoryHandle: FileSystemDirectoryHandle,
    persistenceId: string | null
): Promise<FileSystemDirectoryHandle> {
    const persistedFolderName = getPersistedFolderName(persistenceId);
    return directoryHandle.getDirectoryHandle(persistedFolderName, { create: true });
}
