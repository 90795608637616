import { type PerformanceCoreDatapoint, type PerformanceDatapoint } from 'owa-analytics';
import { DatapointStatus, type CustomWaterfallRange } from 'owa-analytics-types';
import { getNextPaint } from 'owa-analytics-shared';
import { mapToObj } from './utils/mapToObj';

export interface ManagedPerfDatapointType {
    /**
     * Returns the PerformanceDatapoint being managed
     */
    getDatapoint: () => PerformanceDatapoint | PerformanceCoreDatapoint | undefined;

    /**
     * Register a given custom waterfall marker. If all required markers for this datapoint have been git, the datapoint will be ended after the next paint.
     *
     * @param marker Custom Waterfall Index to flag
     * @param waitForNextPaint true if the waterfall marker will be set after next paint. Otherwise will be flagged immediately
     * @param discardMarkerIfDefined
     */
    addCustomWaterfallAndEndIfPossible: (
        marker: CustomWaterfallRange,
        waitForNextPaint?: boolean,
        discardMarkerIfDefined?: boolean
    ) => void;

    /**
     * Checks if all expected custom waterfall markers have been hit, and ends the datapoint if so.
     *
     * @param hasWaitedForNextPaint true if preceding call has already waited for next paint, so datapoint can end immediately. Otherwise end will be called after next paint
     */
    endDatapointIfAllRequiredWaterfallPointsHit: (hasWaitedForNextPaint: boolean) => void;

    /**
     * Indicates that the given custom waterfall marker should be set before attempting to end the datapoint.
     *
     * @param marker Custom Waterfall Index to expect before ending the datapoint
     */
    expectMarkerBeforeEnd: (marker: CustomWaterfallRange) => void;

    /**
     * Ends the datapoint with UserCancelled status immediatelly
     */
    cancelledByUser: () => void;
}

/**
 * Get a new object to manage the lifecycle of a PerformanceDatapoint. Can be used directly as the value of a ManagedPerfDatapointContext provider.
 *
 * @param perfDatapoint Datapoint to manage its lifecycle
 * @param defaultWFMarkersToWait Array of Curstom Waterfall indexes that should be hit before ending the datapoint
 * @param WFRangeToCheckpoint Function that maps Custom Waterfall Index to a checkpoint name
 * @param dpEndCallback Optional callback when the datapoint ends
 * @param onNotifyMarker Optional callback when a Custom Waterfall index is hit
 * @param onExpectMarkerBeforeEnd Optional callback when a new Custom Waterfall index is notified as expected before ending the datapoint
 * @param customAllowEndDatapointCb Optional callback to impose additional restrictions before allowing the datapoint to end
 */
export const getManagedPerfDatapoint = (
    perfDatapoint: PerformanceDatapoint | PerformanceCoreDatapoint | undefined,
    defaultWFMarkersToWait: CustomWaterfallRange[],
    WFRangeToCheckpoint: (index: CustomWaterfallRange) => string,
    dpEndCallback?: (dp: PerformanceDatapoint) => void,
    onNotifyMarker?: (marker: CustomWaterfallRange) => void,
    onExpectMarkerBeforeEnd?: (marker: CustomWaterfallRange) => void,
    customAllowEndDatapointCb: (perfDatapoint: PerformanceDatapoint) => boolean = () => true
): ManagedPerfDatapointType => {
    const notifiedMarkersToWaitMap = new Map<CustomWaterfallRange, number>(
        defaultWFMarkersToWait.map(marker => [marker, 0])
    );

    const addCustomWaterfallAndEndIfPossible = (
        marker: CustomWaterfallRange,
        waitForNextPaint = false,
        discardMarkerIfDefined = true
    ) => {
        const cb = () => {
            if (!!perfDatapoint && !perfDatapoint?.hasEnded) {
                perfDatapoint.addToCustomWaterfall(
                    marker,
                    WFRangeToCheckpoint(marker),
                    discardMarkerIfDefined
                );
                onNotifyMarker?.(marker);
                endDatapointIfAllRequiredWaterfallPointsHit(waitForNextPaint);
            }
        };
        if (waitForNextPaint) {
            getNextPaint(cb);
        } else {
            cb();
        }
    };

    const areAllExpectedMarkersHit = () => {
        for (const marker of notifiedMarkersToWaitMap.keys()) {
            if (!perfDatapoint?.isWaterfallCheckpointDefined(marker)) {
                return false;
            }
        }
        return true;
    };

    const expectMarkerBeforeEnd = (marker: CustomWaterfallRange) => {
        if (!notifiedMarkersToWaitMap.has(marker) && !!perfDatapoint) {
            notifiedMarkersToWaitMap.set(marker, perfDatapoint.calculateTotalDuration());
            onExpectMarkerBeforeEnd?.(marker);
        }
    };

    const endDatapointIfAllRequiredWaterfallPointsHit = (hasWaitedForNextPaint: boolean) => {
        const cb = () => {
            if (
                perfDatapoint &&
                !perfDatapoint.hasEnded &&
                areAllExpectedMarkersHit() &&
                customAllowEndDatapointCb(perfDatapoint)
            ) {
                endDatapoint(true);
            }
        };
        if (hasWaitedForNextPaint) {
            cb();
        } else {
            getNextPaint(cb);
        }
    };

    const endDatapoint = (isNormalEnd: boolean) => {
        if (perfDatapoint) {
            dpEndCallback?.(perfDatapoint);
            perfDatapoint.addCustomData({
                areAllExpectedMarkersHit: areAllExpectedMarkersHit(),
                notifiedMarkersTimings: JSON.stringify(mapToObj(notifiedMarkersToWaitMap)),
                isNormalEnd,
            });
            perfDatapoint.end(
                undefined /* duration */,
                !isNormalEnd ? DatapointStatus.UserCancelled : undefined /* overrideStatus */
            );
        }
    };

    const cancelledByUser = () => {
        if (perfDatapoint && !perfDatapoint.hasEnded) {
            endDatapoint(false);
        }
    };

    return {
        getDatapoint: () => perfDatapoint,
        addCustomWaterfallAndEndIfPossible,
        endDatapointIfAllRequiredWaterfallPointsHit,
        expectMarkerBeforeEnd,
        cancelledByUser,
    };
};
