/* eslint-disable-next-line @typescript-eslint/no-restricted-imports -- (https://aka.ms/OWALintWiki)
 * BASELINE. Do not copy and paste!
 *	> 'dexie' import is restricted from being used. Importing from dexie is allowed but needs an additional approver */
import Dexie from 'dexie';
import { isFeatureEnabled } from 'owa-feature-flags';
import { setTransactionSource, type AppDatabase } from 'owa-offline-database';
import { type MessageTableType, MESSAGES_INSTANCE_KEY } from 'owa-offline-messages-schema';
import { hasTombstoneReason } from 'owa-offline-tombstone';
import { type TombstoneReasonType } from 'owa-offline-tombstone-schema';
import { logComposeOfflineData } from 'owa-offline-compose-logging';

// With most folders, if we mess up the counts in a local lie, they will eventually get fixed up by sync.
// But with a local only folder like outbox, they might be broken forever.

// To avoid that we occassionally fix them up manually to match the contents of the folder
export function refreshOutbox(database: AppDatabase, outboxId: string) {
    return database.transaction(
        'rw',
        database.folders,
        database.messages,
        database.offlineTombstones,
        async transaction => {
            setTransactionSource(transaction, 'sync');
            let messages: MessageTableType[] = await database.messages
                .where(MESSAGES_INSTANCE_KEY)
                .between([outboxId, Dexie.minKey], [outboxId, Dexie.maxKey])
                .toArray();

            // Remove orphan messages
            if (isFeatureEnabled('cmp-offline-compose-tombstones')) {
                // If a non-draft message is in outbox but doesn't have a tombstone, we think it's an orphan item which should be removed
                const messagesToRemove: (MessageTableType | null)[] = await Promise.all(
                    messages.map(async message => {
                        const hasTombstone: boolean = await hasTombstoneReason(
                            database,
                            '',
                            message.ItemId.Id,
                            5
                        );
                        const isDraft: boolean = !!message.IsDraft;
                        return hasTombstone || isDraft ? null : message;
                    })
                );

                const idsToRemove: string[] = messagesToRemove
                    .filter(m => m != null)
                    .map(m => m.ItemId.Id);

                logComposeOfflineData(
                    'RefreshOutbox-RemoveOrphanMessages',
                    {
                        itemIds: idsToRemove.join(','),
                        outboxId,
                    },
                    undefined /* editorId */
                );

                await database.messages.bulkDelete(idsToRemove);
                messages = messages.filter(m => !idsToRemove.includes(m.ItemId.Id));
            }

            // Update message count
            const totalMessageCount = messages.length;
            const UnreadCount = messages.filter(m => !m.IsRead).length;
            return database.folders.update(outboxId, { totalMessageCount, UnreadCount });
        }
    );
}
