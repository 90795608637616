/* eslint-disable-next-line @typescript-eslint/no-restricted-imports -- (https://aka.ms/OWALintWiki)
 * BASELINE. Do not copy and paste!
 *	> 'dexie' import is restricted from being used. Importing from dexie is allowed but needs an additional approver */
import Dexie from 'dexie';
import type { Modifications } from './TableHooks';

export function getNewObjFromMods<T extends object>(oldObj: T, mods: Modifications): T {
    const newObj = Dexie.deepClone(oldObj);
    for (const propPath of Object.keys(mods)) {
        var mod = mods[propPath];
        if (typeof mod === 'undefined') {
            Dexie.delByKeyPath(newObj, propPath);
        } else {
            Dexie.setByKeyPath(newObj, propPath, mod);
        }
    }

    return newObj;
}
