import type { ApolloError, FetchResult } from '@apollo/client';
import type { GraphQLError } from 'graphql';
import type { TraceErrorObject } from 'owa-trace';

export function serializeResultErrors(
    result: FetchResult<Record<string, any>, Record<string, any>, Record<string, any>>
) {
    return !result.errors
        ? result
        : {
              ...result,
              errors: result.errors?.map(se => serializeError(se)) as GraphQLError[],
          };
}

// GraphQLError extends Error, but, the native Error object doesn't clone properly via postMessage.
export function serializeError(
    error: Error | GraphQLError | ApolloError | TraceErrorObject | undefined | null,
    sanity: number = 0
): Error | GraphQLError | ApolloError | TraceErrorObject | undefined | null {
    sanity = sanity + 1;

    if (sanity > 5) {
        // we're using recursion within this method, don't spin
        return Error('Object too complex');
    }

    if (!error) {
        return error;
    }

    const gqlError = error as GraphQLError;
    const apolloError = error as ApolloError;
    const traceError = error as TraceErrorObject;
    return {
        // from Error
        name: error.name,
        message: error.message,
        stack: error.stack,

        // from GraphqlError
        positions: gqlError.positions,
        path: gqlError.path,
        originalError: serializeError(gqlError.originalError, sanity),
        locations: gqlError.locations,
        extensions: gqlError.extensions
            ? JSON.parse(JSON.stringify(gqlError.extensions))
            : undefined,

        // from ApolloError
        networkError: serializeError(apolloError.networkError, sanity),
        graphQLErrors: apolloError.graphQLErrors?.map(e =>
            serializeError(e, sanity)
        ) as GraphQLError[],

        // from TraceErrorObject
        responseCode: traceError.responseCode,
        fetchErrorType: traceError.fetchErrorType,
        additionalInfo: traceError.additionalInfo,
        diagnosticInfo: traceError.diagnosticInfo,
        httpStatus: traceError.httpStatus,
        xowaerror: traceError.xowaerror,
        xinnerexception: traceError.xinnerexception,
    } as Error | GraphQLError | ApolloError | TraceErrorObject;
}
