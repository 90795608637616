import {
    getListUserFeatureAccessLastError,
    loadListUserFeatureAccessInProgress,
} from 'places-analytics-app-store-operations/lib/operations/loadListUserFeatureAccess';
import { ListUserFeatureAccessKey } from 'places-analytics-app-store-operations';
import { getListUserFeatureAccess } from '../store';

/**
 * This function retrieves the user feature access data from the store.
 * It is used to provide a consistent interface for accessing this data,
 * including the loading state and any errors that may have occurred fronm the operations module.
 */
export function getListUserFeatureAccessFromStore() {
    return {
        featureAccess: getListUserFeatureAccess(),
        loading: loadListUserFeatureAccessInProgress(ListUserFeatureAccessKey),
        error: getListUserFeatureAccessLastError(ListUserFeatureAccessKey),
    };
}

export function useListUserFeatureAccessSelector() {
    return getListUserFeatureAccessFromStore();
}
