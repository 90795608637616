import type { DbSchema, Transaction } from 'dexie';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports -- (https://aka.ms/OWALintWiki)
 * BASELINE. Do not copy and paste!
 *	> 'dexie' import is restricted from being used. Importing from dexie is allowed but needs an additional approver */
import Dexie from 'dexie';
import type { TraceErrorObject } from 'owa-trace';

/**
 * The callstacks we see for database errors typically just show generic Dexie code.
 * Here we add a bit more information to help us understand where an error came from.
 */
export function addInfoToErrors(database: Dexie) {
    database._createTransaction = Dexie.override(database._createTransaction, originalFn => {
        return (
            mode: IDBTransactionMode,
            storeNames: ArrayLike<string>,
            dbschema: DbSchema,
            parentTransaction?: Transaction | null
        ) => {
            const transaction: Transaction = originalFn(
                mode,
                storeNames,
                dbschema,
                parentTransaction
            );
            transaction.on('error', error => {
                const traceError = error as TraceErrorObject;
                traceError.additionalInfo = {
                    db: database.name,
                    stores: transaction.storeNames.join(','),
                    mode,
                };
            });
            return transaction;
        };
    });
}
