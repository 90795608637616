export function getValidFileSystemName(name: string): string {
    const invalidCharsRegex = /[<>:"/\\|?*\x00-\x1F]/g; // Invalid characters in Windows file names
    const reservedNamesRegex = /^(con|prn|aux|nul|com[0-9]|lpt[0-9])$/i; // Reserved file names in Windows

    // Url decode the name first as the name is retrieved from URL or HTTP header
    let validName = decodeURIComponent(name);

    // Replace invalid characters with an underscore
    validName = validName.replace(invalidCharsRegex, '_');

    // Replace invalid suffix (period or space)
    validName = validName.replace(/\.$/, '_'); // Replace trailing dot
    validName = validName.replace(/\s+$/, '_'); // Replace trailing spaces with underscore

    // Check if the file name is a reserved name
    if (reservedNamesRegex.test(validName)) {
        // If it's a reserved name, append an underscore to it
        return validName + '_';
    }

    return validName;
}

/**
 * Used to hash a file name, not used for security purposes
 * https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest#converting_a_digest_to_a_hex_string
 */
async function getHashedFileSystemName(name: string) {
    const msgUint8 = new TextEncoder().encode(name); // encode as (utf-8) Uint8Array
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8); // hash the message
    const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
    return hashHex;
}

function shouldHashFileSystemName(name: string) {
    const MAX_LENGTH = 64;
    return name.length > MAX_LENGTH;
}

export async function getValidFileSystemNameWithHashIfNecessary(name: string): Promise<string> {
    let fileNameHasEncodingIssues: boolean = false;
    let fileSystemName: string = name;
    try {
        fileSystemName = getValidFileSystemName(name);
    } catch (e) {
        fileNameHasEncodingIssues = true;
    }
    if (fileNameHasEncodingIssues || name.length === 0 || shouldHashFileSystemName(name)) {
        fileSystemName = await getHashedFileSystemName(name);
    }

    return fileSystemName;
}

export async function getShortenedFileNameIfNeeded(
    fileName: string | undefined,
    extension: string | undefined
): Promise<string | undefined> {
    if (!!extension) {
        extension = '.' + extension;
    } else {
        extension = '';
    }
    const attachmentFileName: string = await getValidFileSystemNameWithHashIfNecessary(
        fileName || ''
    );
    if (!!extension && attachmentFileName.endsWith(extension)) {
        return attachmentFileName;
    }
    const newAttachmentFileName =
        attachmentFileName.substring(0, 64 - extension.length) + extension;

    if (newAttachmentFileName.length > 64) {
        return undefined;
    }
    return newAttachmentFileName;
}
