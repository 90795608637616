/* eslint-disable-next-line @typescript-eslint/no-restricted-imports -- (https://aka.ms/OWALintWiki)
 * BASELINE. Do not copy and paste!
 *	> 'dexie' import is restricted from being used. Importing from dexie is allowed but needs an additional approver */
import Dexie from 'dexie';
import type { Building, Features, Campus, Place } from 'owa-graph-schema';
import type { ReportData } from 'hybridspace-graphql/lib/utils/reportDataType';

export interface UserFeatureAccess {
    ownerId: string;
    featureAccess: Array<Features | null> | undefined;
    timestamp: number;
}

export interface LatestReportDates {
    ownerId: string;
    reportDates: Array<{
        key?: string | null;
        value?: string | null;
    } | null>;
    timestamp: number;
}

export interface BuildingsList {
    ownerId: string;
    buildingsList: string;
    timestamp: number;
}

export interface Buildings {
    ownerId: string;
    buildingId: string;
    building: Building;
    timestamp: number;
}

export interface RoomCount {
    ownerId: string;
    buildingFloorKey: string;
    roomCount: number;
    timestamp: number;
}

export interface DeskpoolCount {
    ownerId: string;
    buildingFloorKey: string;
    deskpoolCount: number;
    timestamp: number;
}

export interface Report {
    ownerId: string;
    reportParamsHashKey: string;
    reportData: ReportData;
    timestamp: number;
}

export interface Campuses {
    ownerId: string;
    campusId: string;
    campus: Campus;
    cityId: string;
    timestamp: number;
}

export interface Floors {
    ownerId: string;
    floorId: string;
    buildingId: string;
    floor: Place;
    timestamp: number;
}

const db = new Dexie('PlacesAnalyticsCache');
db.version(1).stores({
    userFeatureAccess: 'ownerId, [ownerId+timestamp]',
    latestReportDates: 'ownerId, [ownerId+timestamp]',
    buildingsList: 'ownerId, [ownerId+timestamp]',
    buildings: '[ownerId+buildingId], ownerId, [ownerId+timestamp]',
    roomCount: '[ownerId+buildingFloorKey], ownerId, [ownerId+timestamp]',
    deskpoolCount: '[ownerId+buildingFloorKey], ownerId, [ownerId+timestamp]',
    report: '[ownerId+reportParamsHashKey], ownerId, [ownerId+timestamp]',
    campuses: '[ownerId+campusId], [ownerId+cityId], ownerId, [ownerId+timestamp]',
    floors: '[ownerId+floorId], [ownerId+buildingId], ownerId, [ownerId+timestamp]',
});

export const userFeatureAccessTable = db.table<UserFeatureAccess>('userFeatureAccess');
export const latestReportDatesTable = db.table<LatestReportDates>('latestReportDates');
export const buildingsListTable = db.table<BuildingsList>('buildingsList');
export const buildingsTable = db.table<Buildings>('buildings');
export const roomCountTable = db.table<RoomCount>('roomCount');
export const deskpoolCountTable = db.table<DeskpoolCount>('deskpoolCount');
export const reportTable = db.table<Report>('report');
export const campusesTable = db.table<Campuses>('campuses');
export const floorsTable = db.table<Floors>('floors');
