import { isLocalDirectoryAvailable, isDirectoryHandleExpired } from 'owa-attachment-settings';
import { type AttachmentStorageErrorObject } from './fileHandleErrors';

export function isNotFoundError(error: Error): boolean {
    return error.name === 'NotFoundError';
}
``;
export function isSecurityPolicyError(error: Error): boolean {
    return error.name === 'SecurityError';
}

export async function getSafelyHandleValue<
    T extends FileSystemDirectoryHandle | FileSystemFileHandle
>(
    handle: Promise<T | undefined>,
    {
        notFoundError,
        undefinedObjectError,
        noPermissionError,
        notLocallyAvailableError,
        securityError,
    }: AttachmentStorageErrorObject,
    returnUndefinedIfError: boolean = false
): Promise<T | undefined> {
    try {
        const response = await handle;

        if (!response) {
            throw undefinedObjectError;
        }

        if (response.kind === 'directory') {
            // we check for permission if the function has the error to throw
            if (noPermissionError) {
                const isExpired = await isDirectoryHandleExpired(response);
                if (isExpired) {
                    throw noPermissionError;
                }
            }
            // we check for the local file if the function has the error to throw
            if (notLocallyAvailableError) {
                const [isAvailable, error] = await isLocalDirectoryAvailable(
                    response as FileSystemDirectoryHandle
                );
                if (!isAvailable) {
                    notLocallyAvailableError.additionalInfo = {
                        ...notLocallyAvailableError.additionalInfo,
                        originalError: error?.message || undefined,
                    };
                    throw notLocallyAvailableError;
                }
            }
        }

        return response;
    } catch (ex) {
        if (returnUndefinedIfError) {
            return undefined;
        }
        if (isNotFoundError(ex)) {
            notFoundError.additionalInfo = {
                ...notFoundError.additionalInfo,
                originalError: ex.message,
            };
            throw notFoundError;
        }

        if (isSecurityPolicyError(ex) && securityError) {
            securityError.additionalInfo = {
                ...securityError.additionalInfo,
                originalError: ex.message,
            };
            throw securityError;
        }

        throw ex;
    }
}

export async function getSafelyArrayBuffer(
    fileHandle: FileSystemFileHandle
): Promise<ArrayBuffer | undefined> {
    try {
        const file = await fileHandle.getFile();
        if (!file) {
            throw new Error('Cannot get the file from the file handle.');
        }

        return file.arrayBuffer();
    } catch (ex) {
        if (isNotFoundError(ex)) {
            return undefined;
        }
        throw ex;
    }
}
