/* eslint-disable-next-line @typescript-eslint/no-restricted-imports -- (https://aka.ms/OWALintWiki)
 * BASELINE. Do not copy and paste!
 *	> 'dexie' import is restricted from being used. Importing from dexie is allowed but needs an additional approver */
import Dexie from 'dexie';

import type { SessionData } from 'owa-service/lib/types/SessionData';

const INDEXED_DB_NAME = 'placesSession';
export interface PlacesSessionData {
    ownerId: string; // owner
    sessionData: SessionData;
    timestamp: number; // to store the last updated time
}

export const db = new Dexie(INDEXED_DB_NAME);

db.version(1).stores({
    sessionData: 'ownerId',
});

export const placesSessionDataTable = db.table<PlacesSessionData>('sessionData');

export async function deletePlacesSessionDatabase() {
    if (await Dexie.exists(INDEXED_DB_NAME)) {
        await Dexie.delete(INDEXED_DB_NAME);
    }
}
