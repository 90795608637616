import type { AppDatabase } from 'owa-offline-database';
import {
    FOLDERS_WITH_ATTACHMENTS_TO_SYNC_KEY,
    FOLDERS_WITH_IMAGES_TO_SYNC_KEY,
} from 'owa-offline-message-bodies-schema';
import { type SyncKeyType, MAX_MESSAGES_PER_REQUEST } from 'owa-offline-attachments-schema';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports -- (https://aka.ms/OWALintWiki)
 * BASELINE. Do not copy and paste!
 *	> 'dexie' import is restricted from being used. Importing from dexie is allowed but needs an additional approver */
import Dexie from 'dexie';

export const getMessageBodiesIds = (
    database: AppDatabase,
    type: SyncKeyType,
    folderId?: string
): Promise<string[]> => {
    return database.messageBodies
        .where(type === 1 ? FOLDERS_WITH_IMAGES_TO_SYNC_KEY : FOLDERS_WITH_ATTACHMENTS_TO_SYNC_KEY)
        .between([1, folderId ?? Dexie.minKey], [1, folderId ?? Dexie.maxKey], true, true)
        .limit(MAX_MESSAGES_PER_REQUEST)
        .primaryKeys();
};
