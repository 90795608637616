import { logGreyError } from 'owa-analytics';
import type { MailboxInfo } from 'owa-client-types';
import { scrubForPii } from 'owa-config';
import type {
    AcceptedQueuedResult,
    QueuedActionResult,
    RejectedQueuedResult,
} from 'owa-data-worker-utils';
import type { MailFolder } from 'owa-graph-schema';
import { logComposeOfflineData } from 'owa-offline-compose-logging';
import { getDatabase, setTransactionSource } from 'owa-offline-database';
import { tryGetDistinguishedFolder } from 'owa-offline-folders';
import type { QueuedAction } from '../types/QueuedAction';
import { findRejectedResult } from './defaultResultProcessor';

const moveMessageToDrafts = async (itemId: string, mailboxInfo: MailboxInfo) => {
    const database = await getDatabase(mailboxInfo);
    const messagesTable = database.messages;
    const messageBodiesTable = database.messageBodies;
    const foldersTable = database.folders;

    await database.transaction(
        'rw',
        foldersTable,
        messagesTable,
        messageBodiesTable,
        async transaction => {
            setTransactionSource(transaction, 'localLie');
            const message = await messagesTable.get(itemId);
            let outboxFolder: MailFolder | undefined = undefined;
            let draftsFolder: MailFolder | undefined = undefined;

            if (message) {
                outboxFolder = await tryGetDistinguishedFolder(database, 'outbox');
                draftsFolder = await tryGetDistinguishedFolder(database, 'drafts');

                if (outboxFolder) {
                    // Update outbox count only when message is in outbox folder
                    if (message.ParentFolderId?.Id == outboxFolder.id) {
                        await foldersTable.update(outboxFolder.id, {
                            totalMessageCount: Math.max(0, outboxFolder.totalMessageCount - 1),
                        });
                    }
                } else {
                    throw new Error('Cannot find outbox folder');
                }

                if (draftsFolder) {
                    const changes = {
                        ParentFolderId: { Id: draftsFolder.id },
                        IsDraft: true,
                    };
                    await Promise.all([
                        messagesTable.update(itemId, changes),
                        messageBodiesTable.update(itemId, changes),
                    ]);
                } else {
                    throw new Error('Cannot find drafts folder');
                }
            }

            logComposeOfflineData(
                'undoSaveSendProcessor-MoveToDrafts',
                {
                    draftsFound: !!draftsFolder,
                    outboxFound: !!outboxFolder,
                    itemId,
                },
                undefined /* editorId */
            );
        }
    );
};

export async function undoSaveSendResultProcessor(
    action: Omit<QueuedAction, 'id'>,
    result: QueuedActionResult
): Promise<AcceptedQueuedResult | RejectedQueuedResult> {
    let rv: AcceptedQueuedResult | RejectedQueuedResult;
    const rejectedResult = await findRejectedResult(result);

    if (rejectedResult) {
        rv = rejectedResult;
    } else {
        const ID_CHANGES: Map<string, string> = new Map();
        const mailBoxInfo = action.operation.variables.mailboxInfo;
        const itemId: string = action.operation.variables.itemId;
        const isDeletion = action.operation.variables.isDeletion;

        try {
            if (!isDeletion) {
                await moveMessageToDrafts(itemId, mailBoxInfo);
            }
        } catch (err) {
            const errorData = {
                step: 'undoSaveSendResultProcessor-exception',
                itemId,
            };
            logGreyError('MailComposeOfflineAction', err, errorData);
            logComposeOfflineData(
                'undoSaveSendResultProcessor-exception',
                {
                    ...errorData,
                    errorMessage: scrubForPii(err?.message),
                },
                undefined /* editorId */
            );
        }

        rv = {
            fetchResult: result.fetchResult,
            fetchError: result.fetchError,
            idChanges: ID_CHANGES,
        };
    }

    return Promise.resolve(rv);
}
