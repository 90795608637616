import { queryWithError } from 'hybridspace-graphql/lib/utils/queryWithError';
import { GetListUserFeatureAccessDocument } from 'hybridspace-graphql/lib/graphql/__generated__/getListUserFeatureAccessQuery.interface';
import { getCachedUserFeatureAccess } from 'places-analytics-database/lib/selectors/getCachedUserFeatureAccess';
import { upsertUserFeatureAccessToCache } from 'places-analytics-database/lib/selectors/upsertUserFeatureAccessToCache';
import { getUserExternalDirectoryId } from 'accelerator-aad/lib/getUserExternalDirectoryId';
import { logAppLoadApiFailure, logAppLoadApiSuccess } from '../utils/logUtils';

const AppLoadScenario = 'RBACSettingsFetched';

export default async function fetchListUserFeatureAccess() {
    const ownerId = getUserExternalDirectoryId();

    // Fetch from cache
    try {
        const cachedFeatureAccess = await getCachedUserFeatureAccess(ownerId);
        if (cachedFeatureAccess) {
            logAppLoadApiSuccess(AppLoadScenario, 'cache');
            return cachedFeatureAccess;
        }
    } catch (error) {
        logAppLoadApiFailure(
            AppLoadScenario,
            'Error fetching user feature access from cache',
            error
        );
    }

    // Fetch from network
    const networkFeatureAccess = await fetchListUserFeatureAccessFromNetwork();
    const hasAdminAccess = networkFeatureAccess?.includes('AllAdminFeatures') ?? false;

    // Cache the response (async) only if featureAccess is "AllAdminFeatures".
    // This ensures that non-admin users are not cached, allowing immediate access when a user gains admin permission.
    if (hasAdminAccess) {
        upsertUserFeatureAccessToCache(ownerId, networkFeatureAccess);
    }

    return networkFeatureAccess;
}

async function fetchListUserFeatureAccessFromNetwork() {
    try {
        const { data } = await queryWithError(GetListUserFeatureAccessDocument, {
            context: {
                noBatching: true,
            },
            fetchPolicy: 'cache-first',
        });

        logAppLoadApiSuccess(AppLoadScenario, 'network');
        return data?.listUserFeatureAccess?.featureAccess || [];
    } catch (error) {
        logAppLoadApiFailure(
            AppLoadScenario,
            'Error fetching user feature access from network',
            error
        );
        throw error;
    }
}
