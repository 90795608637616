import { lazyWebResolvers } from 'outlook-resolvers-web';
import { lazyOfflineResolvers } from 'outlook-resolvers-offline';
import { lazyPstResolvers } from 'outlook-resolvers-pst';
import type { ResolverImports } from 'owa-apollo-links';
import { isOfflineSyncEnabled } from 'owa-offline-sync-feature-flags';
import type { Resolvers } from 'owa-graph-schema';
import { isSafeModeSession } from 'owa-config/lib/isSafeModeSession';

let offlineEnabledResolvers: ResolverImports;
let nonOfflineEnabledResolvers: ResolverImports;
let pstResolvers: Resolvers;

export async function getResolvers(
    offlineEnabled?: boolean,
    pstEnabled?: boolean
): Promise<ResolverImports> {
    let rv: ResolverImports;
    if (!nonOfflineEnabledResolvers) {
        nonOfflineEnabledResolvers = { web: await lazyWebResolvers.import() };
    }

    rv = nonOfflineEnabledResolvers;

    if (isSafeModeSession()) {
        return rv;
    }

    if (offlineEnabled ?? isOfflineSyncEnabled()) {
        if (!offlineEnabledResolvers) {
            offlineEnabledResolvers = {
                ...rv,
                offline: await lazyOfflineResolvers.import(),
            };
        }

        rv = offlineEnabledResolvers;
    }

    if (pstEnabled) {
        if (!pstResolvers) {
            pstResolvers = await lazyPstResolvers.import();
        }

        rv = {
            ...rv,
            pst: pstResolvers,
        };
    }

    return rv;
}
