/* eslint-disable-next-line @typescript-eslint/no-restricted-imports -- (https://aka.ms/OWALintWiki)
 * BASELINE. Do not copy and paste!
 *	> 'dexie' import is restricted from being used. Importing from dexie is allowed but needs an additional approver */
import Dexie from 'dexie';
import { DashboardTeamPlanSchema, DashboardTeamPlanTableName } from './DashboardTeamPlanSnapshot';

const PLACES_INDEXDB_KEY = 'hybridspace-persistent';

/**
 * Each schema added into this would be a new table in the database.
 * Use selector.ts to create an abstraction for callers to interact with hybridspace-persistent database.
 */
const db = new Dexie(PLACES_INDEXDB_KEY);
db.version(1).stores({ ...DashboardTeamPlanSchema });

const dashboardTeamPlanTable = db.table(DashboardTeamPlanTableName);

async function deletePlacesDatabase() {
    if (await Dexie.exists(PLACES_INDEXDB_KEY)) {
        Dexie.delete(PLACES_INDEXDB_KEY);
    }
}

export { db, dashboardTeamPlanTable, deletePlacesDatabase };
