/**
 * This function checks if the directory is available to use in the local file system.
 * By the time this function is made, there is no method in the API that can tell us
 * if the directory exists or not, we have queryPermission but it doesn't give more details.
 * So we are using a workaround, if the directory is available
 * we will create a test file and remove it immediately. If the directory is not available
 * an exception will be thrown and we will return false.
 */
export async function isLocalDirectoryAvailable(
    directoryHandle: FileSystemDirectoryHandle | undefined
): Promise<[boolean, Error | null]> {
    const TEST_FILE_NAME = 'test';
    if (!directoryHandle) {
        return [false, null];
    }
    try {
        await directoryHandle.getDirectoryHandle(TEST_FILE_NAME, { create: true });
        await directoryHandle.removeEntry(TEST_FILE_NAME);
    } catch (ex) {
        return [false, ex];
    }

    return [true, null];
}
