import type { StorageStrategy } from './StorageStrategy';
import { getAttachmentFromDatabase } from './db/store/getAttachmentFromDatabase';
import { saveAttachmentToDatabase } from './db/store/saveAttachmentToDatabase';
import {
    getAttachmentIdAndPersistenceId,
    type AttachmentIdAndPersistenceId,
} from './db/store/getAttachmentIdAndPersistenceId';
import { deleteAttachmentFromDatabase } from './db/store/deleteAttachmentFromDatabase';
import { getPersistedFolder } from './getPersistedFolder';
import {
    getValidFileSystemNameWithHashIfNecessary,
    getShortenedFileNameIfNeeded,
} from './getValidFileSystemName';
import type { AttachmentTableMetaData, AttachmentTableEntry } from './db/schema';
import type { TraceErrorObject } from 'owa-trace';
import {
    rootDirectoryErrors,
    persistedDirectoryErrors,
    attachmentDirectoryErrors,
    fileHandleErrors,
} from './utils/fileHandleErrors';
import {
    isNotFoundError,
    getSafelyHandleValue,
    getSafelyArrayBuffer,
} from './utils/getSafelyHandleValue';

declare global {
    interface FileSystemFileHandle {
        queryPermission: (descriptor: { mode: 'readwrite' | 'read' }) => PermissionState;
    }
}

// The attachment will be stored in the select directory of the file system.
// Every account will be under a subdirectory of the root directory like "ooa-<persistenceId>",
// then a sub folder named as the attachment id.
// An attachment will be stored as a file in the sub folder with original name, like:
// root/ooa-<persistenceId>/<attachmentId>/<originalFileName>

const contentDispositionHeaderName = 'Content-Disposition';
const contentLengthHeaderName = 'Content-Length';

export class FileSystemStorageStrategy implements StorageStrategy {
    private rootDirectoryHandle: FileSystemDirectoryHandle;

    constructor(rootDirectoryHandle: FileSystemDirectoryHandle) {
        this.rootDirectoryHandle = rootDirectoryHandle;
    }

    async getAttachmentResponse(
        url: string,
        localAttachmentIdAndPersistenceId?: AttachmentIdAndPersistenceId
    ): Promise<Response | undefined> {
        const attachmentIdAndPersistenceId =
            localAttachmentIdAndPersistenceId || getAttachmentIdAndPersistenceId(url);
        if (!attachmentIdAndPersistenceId.attachmentId) {
            // if there is no attachment id, it means the url is not a valid attachment url.
            throw new Error('No attachment id in the url.');
        }

        const attachment = await getAttachmentFromDatabase(attachmentIdAndPersistenceId);
        if (!attachment) {
            return undefined;
        }

        try {
            const persistedDirectoryHandle = await getSafelyHandleValue<FileSystemDirectoryHandle>(
                getPersistedFolder(
                    this.rootDirectoryHandle,
                    attachmentIdAndPersistenceId.persistenceId
                ),
                persistedDirectoryErrors
            );

            const fileSystemName = await getValidFileSystemNameWithHashIfNecessary(
                attachmentIdAndPersistenceId.attachmentId
            );
            if (persistedDirectoryHandle) {
                const attachmentDirectoryHandle =
                    await getSafelyHandleValue<FileSystemDirectoryHandle>(
                        persistedDirectoryHandle.getDirectoryHandle(
                            // The attachment id is used as the directory name, so it needs to be encoded.
                            fileSystemName,
                            {
                                create: true,
                            }
                        ),
                        attachmentDirectoryErrors
                    );

                if (attachmentDirectoryHandle) {
                    const fileHandle = await getSafelyHandleValue<FileSystemFileHandle>(
                        attachmentDirectoryHandle.getFileHandle(attachment.fileName, {
                            create: false,
                        }),
                        fileHandleErrors,
                        false /*returnUndefinedIfError */
                    );

                    if (fileHandle) {
                        const file = await fileHandle.getFile();
                        if (!file) {
                            throw new Error('Cannot get the file from the file handle.');
                        }

                        const arrayBuffer = await file.arrayBuffer();

                        const blob = new Blob([arrayBuffer], { type: file.type });

                        const headers = new Headers();
                        headers.append(
                            contentDispositionHeaderName,
                            `attachment; filename="${attachment.fileName}"`
                        );
                        headers.append(contentLengthHeaderName, attachment.fileSize.toString());

                        const response = new Response(blob, {
                            headers,
                        });

                        return response;
                    }
                }
            }
        } catch (ex) {
            throw ex;
        }
        return undefined;
    }

    async saveAttachmentResponse(
        response: Response,
        metaData?: AttachmentTableMetaData,
        localAttachmentIdAndPersistenceId?: AttachmentIdAndPersistenceId
    ): Promise<AttachmentTableEntry> {
        const attachmentIdAndPersistenceId =
            localAttachmentIdAndPersistenceId || getAttachmentIdAndPersistenceId(response.url);
        if (!attachmentIdAndPersistenceId.attachmentId) {
            // if there is no attachment id, it means the url is not a valid attachment url.
            throw new Error('No attachment id in the url.');
        }

        const blob = await response.blob();
        const fileLength = blob.size;

        const defaultFilename: string = 'NONAME'; // Default filename if not found in the header.
        let filename: string | undefined = '';

        if (!!metaData?.fileName) {
            filename = metaData.fileName;
        } else if (!localAttachmentIdAndPersistenceId) {
            // The header is: attachment; filename="Outlook%20inline%20images.docx"
            const contentDispositionHeader = response.headers.get(contentDispositionHeaderName);
            if (contentDispositionHeader) {
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = filenameRegex.exec(contentDispositionHeader);
                if (matches && matches[1]) {
                    // Extracted filename
                    filename = matches[1].replace(/['"]/g, '');
                }
            }
        }
        //we try to get the extension
        const start = filename?.lastIndexOf('.');
        let extension: string | undefined = undefined;
        if (start > 0) {
            extension = filename?.substring(start + 1).toLowerCase();
        }
        filename = (await getShortenedFileNameIfNeeded(filename, extension)) || defaultFilename;

        // Save the attachment to the database first.
        const item = await saveAttachmentToDatabase(attachmentIdAndPersistenceId, {
            ...metaData,
            attachmentId: attachmentIdAndPersistenceId.attachmentId,
            //fileName must be after metaData to override it
            fileName: filename,
            fileSize: fileLength,
            timeCached: new Date(),
        });

        let fileHandle: FileSystemFileHandle | undefined = undefined;
        let persistedDirectoryHandle: FileSystemDirectoryHandle | undefined = undefined;
        let attachmentDirectoryHandle: FileSystemDirectoryHandle | undefined = undefined;

        const fileSystemName = await getValidFileSystemNameWithHashIfNecessary(
            attachmentIdAndPersistenceId.attachmentId
        );

        try {
            // Errors thrown by the FileSystem API are too generic to scope the root cause,
            // we need to catch them and throw a new error with more information.
            // for every Directory handle, we need to check if it is still valid or not
            this.rootDirectoryHandle = (await getSafelyHandleValue<FileSystemDirectoryHandle>(
                Promise.resolve(this.rootDirectoryHandle),
                rootDirectoryErrors
            )) as FileSystemDirectoryHandle;

            try {
                persistedDirectoryHandle = await getPersistedFolder(
                    this.rootDirectoryHandle,
                    attachmentIdAndPersistenceId.persistenceId
                );
            } catch (ex) {
                const diagnosticInfo = {
                    errorMessage: ex.message,
                    persistenceId: attachmentIdAndPersistenceId.persistenceId,
                    rootDirectoryHandle: !!this.rootDirectoryHandle,
                    ...ex.additionalInfo,
                };
                const error = new Error(
                    'Save - Cannot get the persisted directory handle.'
                ) as TraceErrorObject;
                error.additionalInfo = diagnosticInfo;
                error.stack = ex.stack;
                throw error;
            }
            try {
                attachmentDirectoryHandle = await persistedDirectoryHandle.getDirectoryHandle(
                    fileSystemName,
                    {
                        create: true,
                    }
                );
            } catch (ex) {
                const diagnosticInfo = {
                    errorMessage: ex.message,
                    persistenceId: attachmentIdAndPersistenceId.persistenceId,
                    rootDirectoryHandle: !!this.rootDirectoryHandle,
                    fileSystemName: !!fileSystemName,
                    ...ex.additionalInfo,
                };
                const error: TraceErrorObject = new Error(
                    'Save - Cannot get the attachment directory handle.'
                );
                error.additionalInfo = diagnosticInfo;
                error.stack = ex.stack;
                throw error;
            }

            try {
                fileHandle = await attachmentDirectoryHandle.getFileHandle(filename, {
                    create: true,
                });
            } catch (ex) {
                if ((ex.message as string).includes('Name is not allowed')) {
                    filename = await getShortenedFileNameIfNeeded(undefined, extension);
                    fileHandle = await attachmentDirectoryHandle.getFileHandle(
                        filename || defaultFilename,
                        {
                            create: true,
                        }
                    );
                } else {
                    const diagnosticInfo = {
                        errorMessage: ex.message,
                        persistenceId: attachmentIdAndPersistenceId.persistenceId,
                        rootDirectoryHandle: !!this.rootDirectoryHandle,
                        fileSystemName: !!fileSystemName,
                        fileName: !!(filename || defaultFilename),
                        ...ex.additionalInfo,
                    };
                    const error: TraceErrorObject = new Error('Save - Cannot get the file handle.');
                    error.additionalInfo = diagnosticInfo;
                    error.stack = ex.stack;
                    throw error;
                }
            }
            if (fileHandle) {
                const writable = await fileHandle.createWritable();
                await writable.write(blob);
                await writable.close();
                return item;
            }
            throw new Error('Save - Cannot write to the file handle.');
        } catch (e) {
            // Delete the attachment from the database if failed to save it to the file system.
            await deleteAttachmentFromDatabase(attachmentIdAndPersistenceId);
            // Delete the attachment folder if failed to save it to the file system.
            await persistedDirectoryHandle?.removeEntry(fileSystemName, { recursive: true });

            // throw exception to allow it be logged in the caller.
            throw e;
        }
    }

    async deleteAttachment(
        attachmentIdAndPersistenceId: AttachmentIdAndPersistenceId
    ): Promise<boolean> {
        const { attachmentId, persistenceId } = attachmentIdAndPersistenceId;

        if (!attachmentId) {
            throw new Error('No attachment id');
        }

        const persistedDirectoryHandle = await getPersistedFolder(
            this.rootDirectoryHandle,
            persistenceId
        );

        const fileSystemName = await getValidFileSystemNameWithHashIfNecessary(attachmentId);
        const attachmentDirectoryHandle = await persistedDirectoryHandle.getDirectoryHandle(
            fileSystemName
        );
        if (!attachmentDirectoryHandle) {
            throw new Error('Cannot get the attachment directory handle.');
        }
        // we first delete the attachment from file system
        await persistedDirectoryHandle.removeEntry(fileSystemName, {
            recursive: true,
        });

        // and then delete the attachment from database
        await deleteAttachmentFromDatabase(attachmentIdAndPersistenceId);

        return true;
    }

    async getAttachment(
        url: string,
        localAttachmentIdAndPersistenceId?: AttachmentIdAndPersistenceId
    ): Promise<
        | {
              entry: AttachmentTableEntry;
              arrayBuffer: ArrayBuffer;
          }
        | undefined
    > {
        const { attachmentId, persistenceId } =
            localAttachmentIdAndPersistenceId || getAttachmentIdAndPersistenceId(url);

        if (!attachmentId) {
            throw new Error('No attachment id in the url.');
        }

        const attachment = await getAttachmentFromDatabase({ attachmentId, persistenceId });
        if (!attachment) {
            return undefined;
        }

        let fileSystemName: string | undefined = undefined;
        let persistedDirectoryHandle: FileSystemDirectoryHandle | undefined = undefined;

        try {
            fileSystemName = await getValidFileSystemNameWithHashIfNecessary(attachmentId);

            this.rootDirectoryHandle = (await getSafelyHandleValue<FileSystemDirectoryHandle>(
                Promise.resolve(this.rootDirectoryHandle),
                rootDirectoryErrors
            )) as FileSystemDirectoryHandle;

            persistedDirectoryHandle = await getSafelyHandleValue<FileSystemDirectoryHandle>(
                getPersistedFolder(this.rootDirectoryHandle, persistenceId),
                persistedDirectoryErrors
            );

            if (persistedDirectoryHandle) {
                const attachmentDirectoryHandle =
                    await getSafelyHandleValue<FileSystemDirectoryHandle>(
                        persistedDirectoryHandle.getDirectoryHandle(fileSystemName, {
                            create: true,
                        }),
                        attachmentDirectoryErrors
                    );

                if (attachmentDirectoryHandle) {
                    const fileHandle = await getSafelyHandleValue<FileSystemFileHandle>(
                        attachmentDirectoryHandle.getFileHandle(attachment.fileName, {
                            create: false,
                        }),
                        fileHandleErrors,
                        true /*returnUndefinedIfError */
                    );

                    if (fileHandle) {
                        const arrayBuffer = await getSafelyArrayBuffer(fileHandle);
                        if (arrayBuffer) {
                            return { entry: attachment, arrayBuffer };
                        }
                    }
                }
            }
        } catch (error) {
            await deleteAttachmentFromDatabase({ attachmentId, persistenceId });
            if (persistedDirectoryHandle && fileSystemName) {
                await persistedDirectoryHandle.removeEntry(fileSystemName, { recursive: true });
            }

            if (isNotFoundError(error)) {
                return undefined;
            }
            const diagnosticInfo = {
                errorMessage: error.message,
                persistenceId,
                rootDirectoryHandle: !!this.rootDirectoryHandle,
                persistedDirectoryHandle: !!persistedDirectoryHandle,
                fileSystemName: !!fileSystemName,
            };
            (error as TraceErrorObject).additionalInfo = {
                ...error.additionalInfo,
                ...diagnosticInfo,
            };
            error.stack = error.stack;
            throw error;
        }
        return undefined;
    }

    async updateAttachment(
        attachmentIdAndPersistenceId: AttachmentIdAndPersistenceId,
        metaData: AttachmentTableMetaData
    ): Promise<void> {
        const attachment = await getAttachmentFromDatabase(attachmentIdAndPersistenceId);
        if (!attachment) {
            throw new Error('Attachment not found');
        }

        await saveAttachmentToDatabase(attachmentIdAndPersistenceId, {
            ...attachment,
            ...metaData,
        });
    }
}
