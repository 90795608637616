import {
    areApplicationSettingsInitialized,
    getApplicationSettings,
} from 'owa-application-settings';
import type { MailboxInfo } from 'owa-client-types';
import { getOwaNetVersion } from 'owa-notification-globals';

const owaNetCoreVersionHeader = 'OwaNetCore';
// const owaNetFrameworkVersionHeader = 'OwaAppPool';

export function isNetCoreVersionSupportedInOwa(mailboxInfo: MailboxInfo): boolean {
    return getOwaNetVersion(mailboxInfo) === owaNetCoreVersionHeader;
}

export function shouldConnectNetCoreVersionInShadow(mailboxInfo: MailboxInfo): boolean {
    if (isNetCoreVersionSupportedInOwa(mailboxInfo)) {
        return false;
    }
    if (areApplicationSettingsInitialized(mailboxInfo)) {
        const enabledApis = getApplicationSettings('NetCore', mailboxInfo)?.enabledAPIs;
        const isNotificationChannelEnabledInNetCore =
            enabledApis?.includes('notificationChannel') || enabledApis?.includes('*');
        return isNotificationChannelEnabledInNetCore;
    }
    return false;
}

export function shouldUseCallbacksFromOwaNetCore(mailboxInfo: MailboxInfo): boolean {
    if (isNetCoreVersionSupportedInOwa(mailboxInfo)) {
        return true;
    } else if (shouldConnectNetCoreVersionInShadow(mailboxInfo)) {
        return getApplicationSettings('NetCore', mailboxInfo).shouldUseNetCoreSignalR;
    }
    return false;
}
