import type TxpGriffinSettingsPayload from '../store/schema/TxpGriffinSettingsPayload';
import type TxpSettingsEntryPoint from '../store/schema/TxpSettingsEntryPoint';
import getUserSettingsAction from '../services/getUserSettingsAction';
import { isFeatureEnabled } from 'owa-feature-flags';
import { trace } from 'owa-trace';
import { mutatorAction } from 'satcheljs';
import type { MailboxInfo } from 'owa-client-types';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import { getCalendarConfigurationState } from '../selectors/getCalendarConfigurationState';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { logCoreUsage } from 'owa-analytics';
const reloadPromises = new Map<string, Promise<void>>();
export default async function reloadTxpSettings(
    shouldLogDatapoint?: boolean,
    entryPoint?: TxpSettingsEntryPoint,
    mailboxInfo: MailboxInfo = getGlobalSettingsAccountMailboxInfo()
) {
    const key = getIndexerValueForMailboxInfo(mailboxInfo);
    if (isFeatureEnabled('cal-txp-optionsV2') && !reloadPromises.has(key)) {
        const reloadPromise = reloadTxpSettingsInternal(
            mailboxInfo,
            shouldLogDatapoint,
            entryPoint
        );
        reloadPromises.set(key, reloadPromise);
    }
    await reloadPromises.get(key);
    reloadPromises.delete(key);
}
async function reloadTxpSettingsInternal(
    mailboxInfo: MailboxInfo,
    shouldLogDatapoint?: boolean,
    entryPoint?: TxpSettingsEntryPoint
) {
    try {
        const response = await getUserSettingsAction(
            shouldLogDatapoint,
            entryPoint,
            undefined /* entityType */,
            mailboxInfo
        );
        const responseStatus = response.status;
        setTxpSettings(
            mailboxInfo,
            responseStatus === 200 ? await response.json() : null,
            responseStatus
        );
        if (responseStatus !== 200) {
            logCoreUsage('TXPSettingsResponseError', [responseStatus, response.statusText]);
        }
    } catch (e) {
        trace.warn('[TXP]' + e.message);
        logCoreUsage('TXPSettingsResponseError', e.message);
    }
}
/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
const setTxpSettings = mutatorAction(
    'setTxpSettings',
    (mailboxInfo: MailboxInfo, response: TxpGriffinSettingsPayload, statusCode: number) => {
        getCalendarConfigurationState(mailboxInfo).txpSettings = response;
        getCalendarConfigurationState(mailboxInfo).statusCode = statusCode;
    }
);
