import { StorageStrategyFactory, StorageType } from 'owa-attachment-storage';
import { logGreyError } from 'owa-analytics';
import { isDirectoryHandleExpired } from 'owa-attachment-settings';

export const deleteAttachments = async (
    attachmentIds: string[],
    directoryHandle: FileSystemDirectoryHandle | undefined,
    persistenceId: string | null
): Promise<void> => {
    if (!directoryHandle) {
        throw new Error('Directory handle is not available on deleteAttachments');
    }
    if (await isDirectoryHandleExpired(directoryHandle)) {
        throw new Error('Directory handle is expired on deleteAttachments');
    }
    const factory = new StorageStrategyFactory();
    const storageStrategy = factory.createStorageStrategy(StorageType.FileSystem, directoryHandle);
    for (let i = 0; i < attachmentIds.length; i++) {
        const attachmentId = attachmentIds[i];
        try {
            await storageStrategy.deleteAttachment({
                attachmentId,
                persistenceId,
            });
        } catch (e) {
            const additionalInfo = e.additionalInfo;
            logGreyError('CnexErrorAttachmentDeleteFailed', e, {
                additionalInfo,
            });
            throw e;
        }
    }
};
