import type { GraphQLError } from 'graphql';
import { logGreyError } from 'owa-analytics';
import type { MailboxInfo } from 'owa-client-types';
import type {
    AcceptedQueuedResult,
    QueuedActionResult,
    RejectedQueuedResult,
} from 'owa-data-worker-utils';
import { getDatabase, setTransactionSource } from 'owa-offline-database';
import { tryGetDistinguishedFolder } from 'owa-offline-folders';
import folderNameToId from 'owa-session-store/lib/utils/folderNameToId';
import type { QueuedAction, QueuedOperation } from '../types/QueuedAction';
import { isClientId } from 'owa-identifiers';
import { findRejectedResult } from './defaultResultProcessor';
import { scrubForPii } from 'owa-config';
import type { MailFolder } from 'owa-graph-schema';
import { isFeatureEnabled } from 'owa-feature-flags';
import { refreshOutbox } from 'owa-offline-compose-utils/lib/utils/refreshOutbox';
import { logComposeOfflineData } from 'owa-offline-compose-logging';
import type { MessageTableType } from 'owa-offline-messages-schema';
import type { MessageBodiesTableType } from 'owa-offline-message-bodies-schema';

// export for testing only
export const removeMessageFromOutbox = async (
    itemId: string,
    mailboxInfo: MailboxInfo,
    editorId?: string
) => {
    const database = await getDatabase(mailboxInfo);
    const messagesTable = database.messages;
    const messageBodiesTable = database.messageBodies;
    const foldersTable = database.folders;
    await database.transaction(
        'rw',
        foldersTable,
        messagesTable,
        messageBodiesTable,
        async transaction => {
            setTransactionSource(transaction, 'localLie');

            let shouldRemoveFromOutbox: boolean = false;
            let msgInOutbox: boolean = false;
            let msgInDrafts: boolean = false;
            let message: MessageTableType | undefined = undefined;
            let messageBodies: MessageBodiesTableType | undefined = undefined;

            const outbox: MailFolder | undefined = await tryGetDistinguishedFolder(
                database,
                'outbox'
            );

            if (outbox) {
                message = await messagesTable.get(itemId);
                if (message) {
                    const drafts: MailFolder | undefined = await tryGetDistinguishedFolder(
                        database,
                        'drafts'
                    );
                    const msgFolderId: string | undefined = message.ParentFolderId?.Id;
                    msgInOutbox = msgFolderId === outbox.id;
                    msgInDrafts = !!(drafts && msgFolderId === drafts.id);
                    if (msgInOutbox || msgInDrafts) {
                        shouldRemoveFromOutbox = true;
                        await foldersTable.update(outbox.id, {
                            totalMessageCount: Math.max(0, outbox.totalMessageCount - 1),
                            UnreadCount: Math.max(0, outbox.UnreadCount - (message.IsRead ? 0 : 1)),
                        });

                        if (msgInDrafts) {
                            setTimeout(() => {
                                if (msgInDrafts && outbox) {
                                    refreshOutbox(database, outbox.id);
                                }
                            });
                        }
                    }

                    await messagesTable.delete(itemId);
                }

                messageBodies = await messageBodiesTable.get(itemId);
                if (
                    messageBodies &&
                    messageBodies.ParentFolderId?.Id === outbox.id &&
                    isClientId(itemId)
                ) {
                    await messageBodiesTable.delete(itemId);
                }
            }

            logComposeOfflineData(
                'sendItemProcessor-RemoveFromOutbox',
                {
                    itemId,
                    outboxFound: !!outbox,
                    msgFound: !!message,
                    msgBodiesFound: !!messageBodies,
                    shouldRemoveFromOutbox,
                    msgInOutbox,
                    msgInDrafts,
                    outboxCount: outbox?.totalMessageCount,
                },
                editorId
            );
        }
    );
};

/* If it fails to send synced message (with service id), move it from outbox to drafts
   If if fails to send local message (with client id), keep it in outbox
   export for testing only
*/
export const moveFailedMessage = async (
    itemIds: string[],
    mailboxInfo: MailboxInfo,
    editorId?: string
) => {
    const database = await getDatabase(mailboxInfo);
    const messagesTable = database.messages;
    const messageBodiesTable = database.messageBodies;
    const foldersTable = database.folders;

    logComposeOfflineData(
        'sendItemProcessor-MoveFailedMessage',
        { itemIds: itemIds.join(',') },
        editorId
    );

    await database.transaction(
        'rw',
        foldersTable,
        messagesTable,
        messageBodiesTable,
        async transaction => {
            setTransactionSource(transaction, 'localLie');

            for (const itemId of itemIds) {
                const newMessage = await messagesTable.get(itemId);
                const messageBody = await messageBodiesTable.get(itemId);

                if (newMessage && messageBody) {
                    const isLocalMsg: boolean = isClientId(itemId);
                    if (isLocalMsg) {
                        const changes = { IsDraft: true };
                        await Promise.all([
                            messagesTable.update(itemId, changes),
                            messageBodiesTable.update(itemId, changes),
                        ]);
                    } else {
                        const draftsFolderId = folderNameToId('drafts', mailboxInfo);
                        if (draftsFolderId) {
                            const changes = {
                                ParentFolderId: { Id: draftsFolderId },
                                IsDraft: true,
                            };
                            await Promise.all([
                                messagesTable.update(itemId, changes),
                                messageBodiesTable.update(itemId, changes),
                            ]);

                            const outbox = await tryGetDistinguishedFolder(database, 'outbox');
                            if (outbox) {
                                await foldersTable.update(outbox.id, {
                                    totalMessageCount: Math.max(0, outbox.totalMessageCount - 1),
                                });
                            }
                        } else {
                            throw new Error('Cannot find target folder');
                        }
                    }

                    logComposeOfflineData(
                        isLocalMsg
                            ? 'sendItemProcessor-MoveFailedOutbox'
                            : 'sendItemProcessor-MoveFailedDrafts',
                        { itemId },
                        editorId
                    );

                    break;
                }
            }
        }
    );
};

/* The result process for sending draft or smart response */
export async function sendItemProcessor(
    action: Omit<QueuedAction, 'id'>,
    result: QueuedActionResult
): Promise<AcceptedQueuedResult | RejectedQueuedResult> {
    let rv: AcceptedQueuedResult | RejectedQueuedResult;
    const error: GraphQLError | undefined = result.fetchResult?.errors?.[0];

    const rejectedResult = await findRejectedResult(result);
    if (rejectedResult) {
        rv = rejectedResult;
    } else {
        const ID_CHANGES: Map<string, string> = new Map();
        const localItemId =
            action.operation.context.queuedAction.localResult?.data?.sendItem?.draft?.ItemId;
        const mailBoxInfo = action.operation.variables.draft.mailboxInfo;
        const clientId: string | undefined = localItemId?.Id;
        const serverId: string = action.operation.variables.itemId.Id;
        const itemIds: string[] = Array.from(
            new Set([clientId, serverId].filter(id => !!id) as string[])
        );
        const editorId: string | undefined = (action.operation as QueuedOperation).context.editorId;

        try {
            if (result.fetchResult?.data) {
                for (const itemId of itemIds) {
                    if (
                        !isFeatureEnabled('cmp-offline-removeOutboxItemUponTransport') ||
                        isClientId(itemId)
                    ) {
                        await removeMessageFromOutbox(itemId, mailBoxInfo, editorId);
                    }
                }
            } else {
                await moveFailedMessage(itemIds, mailBoxInfo, editorId);
            }
        } catch (err) {
            const errorData = {
                step: 'sendItemProcessor-exception',
                editorId,
                clientId,
                serverId,
            };
            logGreyError('MailComposeOfflineAction', err, errorData);
            logComposeOfflineData(
                'sendItemProcessor-Exception',
                {
                    clientId,
                    serverId,
                    errorMessage: scrubForPii(err?.message),
                },
                editorId
            );
        }

        logComposeOfflineData(
            'sendItemProcessor-Accpeted',
            {
                clientId,
                serverId,
                fetchResultData: !!result.fetchResult?.data,
                hasError: !!error,
                errorMessage: scrubForPii(error?.message),
            },
            editorId
        );

        rv = {
            fetchResult: result.fetchResult,
            fetchError: result.fetchError,
            idChanges: ID_CHANGES,
        };
    }

    return Promise.resolve(rv);
}
