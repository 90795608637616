import type { DexieKey } from 'owa-database-schema';

export interface DirectoryHandleEntry {
    id: string;
    handle: FileSystemDirectoryHandle;
}

export const ROOT_DIRECTORY_HANDLE_ID = 'root';
export const DIRECTORY_HANDLES_PRIMARY_KEY: DexieKey<DirectoryHandleEntry> = 'id';

export const directoryHandlesSchema = {
    directoryHandles: `${DIRECTORY_HANDLES_PRIMARY_KEY}`,
};
