import { isFeatureEnabled } from 'owa-feature-flags';
import { logCoreUsage } from 'owa-analytics';

interface MailComposeInfo {
    [key: string]: string | boolean | undefined | number | null;
}

/**
 * Function to store the log mail compose save/send
 * @param eventName the event name to log
 * @param editorId the editor id of the compose form
 * @param mailComposeInfo the mail compose info to log
 */

export default function logMailComposeSaveSend(
    eventName: string,
    editorId: string | undefined,
    mailComposeInfo: MailComposeInfo
) {
    if (isFeatureEnabled('cmp-compose-send-logs')) {
        logCoreUsage('MailComposeSaveSendLog', {
            editorId: editorId || 'EditorIdNotFound',
            eventName,
            ...mailComposeInfo,
        });
    }
}
