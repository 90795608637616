import { ObservableMap } from 'mobx';
import { createAccessors } from 'owa-viewstate-store';
import { createStore } from 'satcheljs';
import type { Building, Features, Campus, Place } from 'owa-graph-schema';
import type { ReportData } from 'hybridspace-graphql/lib/utils/reportDataType';

const getStore = createStore('places-analytics-app-store', {
    listUserFeatureAccess: undefined as Array<Features | null> | undefined,
    latestReportDatesData: undefined as
        | Array<{
              key?: string | null;
              value?: string | null;
          } | null>
        | undefined,
    buildingsListString: undefined as string | undefined,
    buildings: undefined as Building[] | undefined,
    campusesMap: new ObservableMap<string, Campus[]>(), // Map of cityId to campuses
    floorsMap: new ObservableMap<string, Place[]>(), // Map of buildingId to floors
    // for rooms & deskpool count map, key is the combination of buildingId & floorId `${buildingId}_${floorId || 'ALL_FLOORS'}`
    roomsCountMap: new ObservableMap<string, number>(),
    deskPoolsCountMap: new ObservableMap<string, number>(),
    reportMap: new ObservableMap<string, ReportData>(),
});

export const store = getStore();

export const {
    getListUserFeatureAccess,
    setListUserFeatureAccess,
    getLatestReportDatesData,
    setLatestReportDatesData,
    getBuildingsListString,
    setBuildingsListString,
    getBuildings,
    setBuildings,
    getCampusesMap,
    setCampusesMap,
    getFloorsMap,
    setFloorsMap,
    getRoomsCountMap,
    setRoomsCountMap,
    getDeskPoolsCountMap,
    setDeskPoolsCountMap,
    getReportMap,
    setReportMap,
} = createAccessors('places-analytics-app-store', getStore);
