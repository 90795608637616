import { getEntryPoint } from 'hybridspace-telemetry';
import { DatapointStatus, PerformanceDatapoint } from 'owa-analytics';
import { query } from 'owa-apollo';

import {
    ApolloError,
    type ApolloQueryResult,
    type OperationVariables,
    type TypedDocumentNode,
} from '@apollo/client';
import type { OperationDefinitionNode } from 'graphql';
import type { StrictQueryOptions, StrictVariables } from 'owa-apollo';
import type { CustomData } from 'owa-analytics-types';
interface CustomOptions {
    shouldLog?: (error: ApolloError) => boolean;
    customData?: CustomData;
}

export async function queryWithError<
    TSchemaVars extends OperationVariables,
    TActualVars extends StrictVariables<TSchemaVars, TActualVars>,
    TData
>(
    queryDoc: TypedDocumentNode<TData, TSchemaVars>,
    options: StrictQueryOptions<TActualVars, TData> = {},
    customOptions: CustomOptions = {}
): Promise<ApolloQueryResult<TData>> {
    const operationName = (queryDoc.definitions[0] as OperationDefinitionNode)?.name?.value;
    const perfDatapoint = new PerformanceDatapoint('PlacesQueryWithError');
    const customData = customOptions?.customData;
    perfDatapoint.addCustomData({
        entryPoint: getEntryPoint(),
        operationName,
        ...customData,
    });
    let schemaResult: ApolloQueryResult<TData>;
    try {
        schemaResult = await query(queryDoc, options);
        const resultError = schemaResult?.error;
        if (resultError) {
            const graphqlError = resultError.graphQLErrors?.[0];
            const networkError = resultError.networkError;
            perfDatapoint.endWithError(
                networkError || (graphqlError?.extensions?.code?.indexOf('SERVER') ?? 0) > -1
                    ? DatapointStatus.ServerError
                    : DatapointStatus.ClientError
            );
            throw new ApolloError(resultError);
        } else {
            perfDatapoint.end();
        }
    } catch (error) {
        perfDatapoint.endWithError(DatapointStatus.ServerError, error);
        throw error;
    }

    return schemaResult as ApolloQueryResult<TData>;
}
