import type {
    AcceptedQueuedResult,
    QueuedActionResult,
    RejectedQueuedResult,
} from 'owa-data-worker-utils';
import type { QueuedAction } from '../types/QueuedAction';
import { findRejectedResult } from './defaultResultProcessor';
import type { AttachmentId } from 'owa-graph-schema';
import type { MailboxInfo } from 'owa-client-types';
import { getDatabaseId } from 'owa-offline-database';
import { getDirectoryHandleSetting } from 'owa-attachment-settings';
import {
    deleteAttachments,
    getAttachmentById,
    saveAttachments,
    createResponseFromBlob,
    logAttachmentOfflineData,
} from 'owa-offline-attachments-common';
import {
    getAttachmentOfflineActionDatapoint,
    type AttachmentOfflineDatapointOptions,
} from 'owa-offline-attachments-logging';
import { DatapointStatus } from 'owa-analytics-types';
import { type PerformanceDatapoint } from 'owa-analytics';

export async function updateIDBAttachmentId(
    clientAttachmentId: AttachmentId,
    serverAttachmentId: AttachmentId,
    itemId: string,
    blob: Blob,
    mailboxInfo: MailboxInfo,
    dp: PerformanceDatapoint
) {
    const datapointOptions: AttachmentOfflineDatapointOptions = {
        action: 'addAttachmentToDraft',
        step: 'resultProcessor',
    };
    const persistenceId = getDatabaseId(mailboxInfo);
    const directoryHandle = await getDirectoryHandleSetting();

    //get attachment from IDB
    const existingAttachment = await getAttachmentById(
        clientAttachmentId.Id,
        directoryHandle,
        persistenceId
    );
    logAttachmentOfflineData(dp, datapointOptions, {
        existingAttachment: !!existingAttachment,
    });

    if (existingAttachment) {
        const { entry } = existingAttachment;

        const response = createResponseFromBlob(entry.fileName, blob);

        const updatedAttachment = {
            attachmentId: serverAttachmentId.Id,
            messageBodyId: itemId,
            fileName: entry.fileName,
            fileSize: entry.fileSize,
            response,
        };

        // we have to save the attachment with the new attachmentId
        // as this creates a new entry in the IDB and local file
        await saveAttachments([updatedAttachment], directoryHandle, persistenceId);
        logAttachmentOfflineData(dp, datapointOptions, {
            attachmentUpdated: true,
        });

        // delete old attachment from IDB and file system
        await deleteAttachments([clientAttachmentId.Id], directoryHandle, persistenceId);
        logAttachmentOfflineData(dp, datapointOptions, {
            attachmentDeleted: true,
        });
        return;
    }
    logAttachmentOfflineData(dp, datapointOptions, {
        updateError: 'The attachment could not be updated',
    });
}

export async function addAttachmentToDraftResultProcessor(
    action: Omit<QueuedAction, 'id'>,
    result: QueuedActionResult
): Promise<AcceptedQueuedResult | RejectedQueuedResult> {
    let resultValue: AcceptedQueuedResult | RejectedQueuedResult;
    const datapointOptions: AttachmentOfflineDatapointOptions = {
        action: 'addAttachmentToDraft',
        step: 'resultProcessor',
    };
    const dp: PerformanceDatapoint = getAttachmentOfflineActionDatapoint(datapointOptions);
    const rejectedResult = await findRejectedResult(result);
    if (rejectedResult) {
        dp.endWithError(DatapointStatus.ClientError, rejectedResult.rejectError);
        resultValue = rejectedResult;
    } else {
        const mailboxInfo = action.operation.variables.options.mailboxInfo;
        const ID_CHANGES: Map<string, string> = new Map();

        const fetchResultData = result.fetchResult?.data;
        if (fetchResultData) {
            const clientAttachmentId: AttachmentId =
                action.operation.variables.options.Attachment.AttachmentId;
            const serverAttachment = fetchResultData.addAttachmentToDraft.attachment;
            const serverAttachmentId: AttachmentId = serverAttachment.AttachmentId;
            const parentItemId = action.operation.variables.options.ParentItemId;
            const blob = action.operation.variables.options.fileBlob;

            logAttachmentOfflineData(dp, datapointOptions, {
                clientAttachmentId: clientAttachmentId.Id,
                serverAttachmentId: serverAttachmentId.Id,
                itemId: parentItemId.Id,
                editorId: action.operation.context.editorId,
                blobSize: blob.size,
            });

            try {
                await updateIDBAttachmentId(
                    clientAttachmentId,
                    serverAttachmentId,
                    parentItemId.Id,
                    blob,
                    mailboxInfo,
                    dp
                );
                ID_CHANGES.set(clientAttachmentId.Id, serverAttachmentId.Id);
                ID_CHANGES.set(
                    clientAttachmentId.Id + 'RootItemChangeKey',
                    serverAttachmentId.RootItemChangeKey || ''
                );
                ID_CHANGES.set(
                    clientAttachmentId.Id + 'RootItemId',
                    serverAttachmentId.RootItemId || ''
                );
                dp.end();
            } catch (ex) {
                dp.endWithError(DatapointStatus.ClientError, ex);
            }
        } else {
            const error = result.fetchResult?.errors?.[0];
            dp.endWithError(DatapointStatus.ServerError, error);
        }

        resultValue = {
            fetchResult: result.fetchResult,
            fetchError: result.fetchError,
            idChanges: ID_CHANGES,
        };
    }

    return Promise.resolve(resultValue);
}
