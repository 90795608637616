import { USER_FEATURE_ACCESS_CACHE_EXPIRY_TIME } from '../constants/cacheExpirtyConstants';
import { userFeatureAccessTable } from '../database';
import type { Features } from 'owa-graph-schema';
import { clearExpiredCache, isCacheValid } from '../utils';
import { logGreyError } from 'owa-analytics';

/**
 * Retrieves cached user feature access data for the specified ownerId, ensuring expired entries
 * are cleaned up beforehand and the retrieved data is validated for freshness.
 */
export async function getCachedUserFeatureAccess(
    ownerId: string
): Promise<(Features | null)[] | undefined> {
    clearExpiredCache(userFeatureAccessTable, ownerId, USER_FEATURE_ACCESS_CACHE_EXPIRY_TIME).catch(
        error => {
            logGreyError('PlacesAnalytics_ClearUserFeatureAccessIDBCache_Error', error);
        }
    );

    // Retrieve and validate cache
    const cachedData = await userFeatureAccessTable.get(ownerId);
    if (cachedData && isCacheValid(cachedData.timestamp, USER_FEATURE_ACCESS_CACHE_EXPIRY_TIME)) {
        return cachedData?.featureAccess;
    }

    return undefined;
}
