import type { AttachmentResponse } from 'owa-offline-attachments-schema';
import { StorageStrategyFactory, StorageType } from 'owa-attachment-storage';
import { logGreyError } from 'owa-analytics';
import { isDirectoryHandleExpired } from 'owa-attachment-settings';
import { getExtensionWithoutDotFromFileNameForLogging } from 'owa-file';

export const saveAttachments = async (
    attachments: AttachmentResponse,
    rootDirectoryHandle: FileSystemDirectoryHandle | undefined,
    persistenceId: string | undefined
) => {
    if (!rootDirectoryHandle) {
        throw new Error('Directory handle is not available on saveAttachments');
    }
    const isExpired = await isDirectoryHandleExpired(
        rootDirectoryHandle,
        (error: Error, reason: string, name: string) => {
            logGreyError('CnexErrorDirectoryHandleExpired', error, {
                reason,
                name,
            });
        }
    );
    if (isExpired) {
        throw new Error('Directory handle is expired on saveAttachments');
    }
    const factory = new StorageStrategyFactory();
    const storageStrategy = factory.createStorageStrategy(
        StorageType.FileSystem,
        rootDirectoryHandle
    );
    for (let i = 0; i < attachments.length; i++) {
        const attachment = attachments[i];
        if (attachment.messageBodyId) {
            try {
                await storageStrategy.saveAttachmentResponse(
                    attachment.response,
                    {
                        messageBodyId: attachment.messageBodyId,
                        fileName: persistenceId ? attachment.fileName : undefined,
                    },
                    persistenceId
                        ? { attachmentId: attachment.attachmentId, persistenceId }
                        : undefined
                );
            } catch (e) {
                const additionalInfo = e.additionalInfo;
                logGreyError('CnexErrorAttachmentsaveFailed', e, {
                    name: !!attachment.fileName,
                    fileNameLength: attachment.fileName.length,
                    extension: getExtensionWithoutDotFromFileNameForLogging(attachment.fileName),
                    messageBodyId: attachment.messageBodyId,
                    rootDirectoryHandleExpired: isExpired,
                    ...additionalInfo,
                });
            }
        }
    }
};
