import { format } from 'owa-localize';
import isConsumer from 'owa-session-store/lib/utils/isConsumer';
import { getOwaCanaryCookie } from 'owa-service/lib/canary';
import { OWA_ROOT_PREFIX, ATTACHMENT_PRINT_HTML_PATHNAME } from '../constants';
import { getClientVersion, getClientId } from 'owa-config';
import getScopedPath from 'owa-url/lib/getScopedPath';
import { getUrlDataForUserIdentity } from '../utils/getUrlDataForUserIdentity';
import type { ClientAttachmentId } from 'owa-client-ids';
import { getRoutingKeyForMailboxInfo } from 'owa-files-url-common';
import { logUsage } from 'owa-analytics';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import { isFeatureEnabled } from 'owa-feature-flags';
import { getApplicationSettings } from 'owa-application-settings';
import { getOwaNetCoreEndpoint } from 'owa-netcore-flight/lib/getOwaNetCoreEndpoint';
import { isOwaNetCoreApiEnabled } from 'owa-netcore-flight/lib/isOwaNetCoreApiEnabled';
import type { MailboxInfo } from 'owa-client-types';

export default function getDownloadUrlForClassicAttachment(
    id: ClientAttachmentId,
    relativeUrlTemplate: string,
    includeDownloadToken: boolean,
    isPrint: boolean = false
): string | null {
    const mailboxInfo = id.mailboxInfo ?? getModuleContextMailboxInfo();
    // Attempt to log some information here to catch cases where ID or MailboxInfo is falsy.
    if (!id || !id.Id || !mailboxInfo) {
        logUsage('getDownloadUrlForClassicAttachment_MissingId', {
            falsyProperty: !id ? 'root' : !id.Id ? 'Id' : !mailboxInfo ? 'mailboxInfo' : 'unknown',
        });
        return null;
    }
    const filesUrlData = getUrlDataForUserIdentity(mailboxInfo);
    const downloadUrlBase = fixDownloadUrlBase(filesUrlData?.downloadUrlBase);
    const routingKey = getRoutingKeyForMailboxInfo(mailboxInfo);

    const attachmentUrl = format(relativeUrlTemplate, encodeURIComponent(id.Id));
    if (!downloadUrlBase) {
        const legacyUrl = OWA_ROOT_PREFIX + attachmentUrl + '&X-OWA-CANARY=' + getOwaCanaryCookie();
        return getScopedPath(legacyUrl);
    }

    let formatString: string = '{0}{1}/{2}';
    if (isConsumer(undefined /* smtpAddress */, mailboxInfo)) {
        formatString += '&isc=1';
    }

    // This default value will appear in gulp deployments, but in production
    //  the actual version will be used instead.
    let result: string = format(formatString, downloadUrlBase, routingKey, attachmentUrl);

    if (includeDownloadToken) {
        result += '&token=' + filesUrlData?.downloadToken;
        result += '&X-OWA-CANARY=' + getOwaCanaryCookie();
    }

    let hostname: string = '';

    const locationURL = new URL(location.origin);
    hostname = locationURL.hostname;
    result += '&owa=' + hostname;
    result += '&scriptVer=' + getClientVersion() || 'unofficial';
    result += '&clientId=' + getClientId() || '';

    if (isFeatureEnabled('doc-pdfprint-html') && isPrint) {
        const resultUrl = new URL(result);
        resultUrl.searchParams.set('routingKey', routingKey);
        resultUrl.pathname = ATTACHMENT_PRINT_HTML_PATHNAME;
        result = resultUrl.toString();
    }

    return isPrint ? result : updatePathForNetCore(result, mailboxInfo);
}

function updatePathForNetCore(url: string, mailboxInfo: MailboxInfo): string {
    try {
        const parsedUrl = new URL(url);
        const pathnameParts = parsedUrl.pathname.split('/');
        const actionName = pathnameParts[pathnameParts.length - 1];
        if (isOwaNetCoreApiEnabled(actionName, getApplicationSettings, mailboxInfo)) {
            parsedUrl.pathname = getOwaNetCoreEndpoint(parsedUrl.pathname);
            return parsedUrl.toString();
        }
    } catch (error) {
        // If the URL is not valid, we will return the original URL.
    }

    return url;
}

// temporary solution for startupdata.ashx in .NET Core returning
// a downloadUrlBase with /owanetcore/ instead of /owa/
// this will be removed after the .NET Core service is the default
function fixDownloadUrlBase(downloadUrlBase: string | undefined): string | undefined {
    return downloadUrlBase?.replace('/owanetcore/', '/owa/');
}
