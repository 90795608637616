import type { AttachmentIdAndPersistenceId } from './getAttachmentIdAndPersistenceId';
import { getDatabase } from '../database';
import type { AttachmentTableEntry } from '../schema';

export async function getAttachmentFromDatabase(
    attachmentIdAndPersistenceId: AttachmentIdAndPersistenceId
): Promise<AttachmentTableEntry | undefined> {
    const attachmentDatabase = await getDatabase(attachmentIdAndPersistenceId.persistenceId);
    const attachment = await attachmentDatabase.attachments.get(
        attachmentIdAndPersistenceId.attachmentId
    );
    return attachment;
}
