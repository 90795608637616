import { mutatorAction } from 'satcheljs';
import { placesExploreDiagnosticsStore } from './store';

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const updateExploreDiagnosticsPageState = mutatorAction(
    '__DIAGNOSTICS_UPDATE_EXPLORE_DIAGNOSTICS_PAGE_STATE',
    (pageState: string) => {
        placesExploreDiagnosticsStore.devPageState = pageState;
    }
);

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const updateExploreDiagnosticsErrorState = mutatorAction(
    '__DIAGNOSTICS_UPDATE_EXPLORE_DIAGNOSTICS_ERROR_STATE',
    (errorState: string) => {
        placesExploreDiagnosticsStore.devErrorPageState = errorState;
    }
);

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const updateExploreDiagnosticsComponentState = mutatorAction(
    '__DIAGNOSTICS_UPDATE_EXPLORE_DIAGNOSTICS_COMPONENT_STATE',
    (componentState: string) => {
        placesExploreDiagnosticsStore.devComponentState = componentState;
    }
);
