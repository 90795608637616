import type { Table } from 'dexie';

/**
 * Checks if a cached entry is still valid based on its timestamp and the specified expiry time.
 */
export const isCacheValid = (timestamp: number, expiryTime: number): boolean => {
    return timestamp > getCurrentTimestamp() - expiryTime;
};

/**
 * Clears expired entries from the provided table based on the given expiry time and ownerId.
 *
 * Assumption: The table holds responses for only one API.
 * This is why additional identifiers (like API ID or name) are not used in this query.
 * If the table were to store data for multiple APIs in the future, we would need to
 * include an additional identifier (e.g., API ID or name) in the composite index
 * and adjust the query accordingly.
 *
 * Note: Currently, this query uses a composite index on [ownerId+timestamp] to efficiently filter
 * entries belonging to the given ownerId and exceeding the expiry threshold.
 */
export const clearExpiredCache = async (
    table: Table,
    ownerId: string,
    expiryTime: number
): Promise<void> => {
    const expiryThreshold = getCurrentTimestamp() - expiryTime;
    await table.where('[ownerId+timestamp]').below([ownerId, expiryThreshold]).delete();
};

export const getCurrentTimestamp = (): number => Math.floor(Date.now() / 1000);
