/* eslint-disable-next-line @typescript-eslint/no-restricted-imports -- (https://aka.ms/OWALintWiki)
 * BASELINE. Do not copy and paste!
 *	> 'dexie' import is restricted from being used. Importing from dexie is allowed but needs an additional approver */
import Dexie from 'dexie';
import type { Table } from 'dexie';
import { DatabaseManager } from 'owa-database-manager';
import { addInfoToErrors } from 'owa-database-utils/lib/addInfoToErrors';
import { enforceVersioningWithoutLogging } from 'owa-database-utils/lib/enforceVersioningWithoutLogging';
import { attachmentsSchema } from './schema';
import type { AttachmentTableEntry } from './schema';
import { directoryHandlesSchema, type DirectoryHandleEntry } from './schema/directoryHandlesSchema';

const DATABASE_NAME = 'owa-attachment-data';

export class AttachmentDatabase extends Dexie {
    attachments!: Table<AttachmentTableEntry>;
    directoryHandles!: Table<DirectoryHandleEntry>;

    constructor(name: string) {
        super(name);
        this.version(1).stores({ ...attachmentsSchema });
        this.version(2).stores({ ...directoryHandlesSchema });

        addInfoToErrors(this);
        enforceVersioningWithoutLogging(this);
    }
}

const databaseManager = new DatabaseManager(DATABASE_NAME, AttachmentDatabase);

export function getDatabase(persistenceId: string | null): Promise<AttachmentDatabase> {
    return databaseManager.getDatabase(undefined, persistenceId ?? '');
}

export function getDatabases(): Map<string, Promise<AttachmentDatabase>> {
    return databaseManager.databases;
}

export async function getPersistedDatabaseIds(includeDefault: boolean = false): Promise<string[]> {
    return databaseManager.getPersistedDatabaseIds(includeDefault);
}

export function deleteDatabase(persistenceId: string | null): Promise<void> {
    return databaseManager.deleteDatabase(persistenceId ?? '');
}
