import { ObservableMap } from 'mobx';
import { getISOString } from 'owa-datetime';
import { createAccessors } from 'owa-viewstate-store';
import { createStore, mutatorAction } from 'satcheljs';

import type { DateRange } from 'owa-datetime-utils';
import type { Collaborator } from 'hybridspace-common/lib/serviceTypes';
import type { WorkLocationSurfaceType } from 'owa-calendar-working-hours-location-utils';
import type { Location } from 'owa-graph-schema';

const getStore = createStore('places-people-schedule-store', {
    collaboratorScheduleLocations: new ObservableMap<
        string,
        Map<
            number,
            {
                workLocationSurfaceType: WorkLocationSurfaceType | 'Error';
                buildingId?: Location['id'];
            }
        >
    >(),
    collaboratorsOfficeLocations: new ObservableMap<number, Map<string, string[]>>(),
    savedCollaborators: [] as Collaborator[],
    suggestedCollaborators: [] as Collaborator[],
    serviceCallsCompleted: [] as string[],
});

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const setCollaboratorLocationsServiceCallComplete = mutatorAction(
    'setCollaboratorLocationsServiceCallComplete',
    (dateRange: DateRange) => {
        const key = getISOString(dateRange.start) + '--' + getISOString(dateRange.end);
        if (getStore().serviceCallsCompleted.indexOf(key) === -1) {
            getStore().serviceCallsCompleted.push(key);
        }
    }
);

export const {
    getCollaboratorsOfficeLocations,
    setCollaboratorsOfficeLocations,
    getCollaboratorScheduleLocations,
    setCollaboratorScheduleLocations,
    getSavedCollaborators,
    setSavedCollaborators,
    getSuggestedCollaborators,
    setSuggestedCollaborators,
    getServiceCallsCompleted,
} = createAccessors('places-people-schedule-store', getStore);
