import type NotificationCallback from './schema/NotificationCallback';
import type NotificationSubscription from './schema/NotificationSubscription';
import { registerSubscription } from './subscription/subscriptionSubmitter';
import { MultiAccountSubscriptions } from './subscription/multiAccountSubscriptions';
import { MailboxBasedSubscriptionTracker } from './subscription/subscriptionTracker';
import {
    getGlobalSettingsAccountMailboxInfo,
    getMailboxInfoForSettingsAccounts,
} from 'owa-account-source-list-store';
import { getSupportedConnectionTypesForNotification } from './subscription/supportedNotifications';

let subscribeCb: typeof subscribe | undefined;
export function setSubscribeCb(callback: typeof subscribe) {
    subscribeCb = callback;
}

export default function subscribe(
    subscription: NotificationSubscription,
    callback: NotificationCallback,
    completionCallback?: () => void // This callback is called when the subscription or first subscriptions for multiaccountsubscription is registered with the server (or after 5 seconds)
): void {
    if (subscribeCb) {
        subscribeCb(subscription, callback);
    } else {
        let subscribeCompletionCallback = () => {}; // Noop
        if (completionCallback) {
            let callbackCalled = false;
            const completionCallbackCalledWithCheck = () => {
                if (!callbackCalled) {
                    callbackCalled = true;
                    completionCallback();
                }
            };

            // We batch the requests before going to the service for 0.5 seconds. We call one of the completion promise from batch depending on which subscribe comes first otherwise we rely on this timeout promise.
            // And based on local debugging generally the time taken for the first subscribe call to come back from service after this function is called is around 3 seconds. So 5 seconds should be a sufficient time to wait for the subscribe call to come back.
            const timeoutId = setTimeout(completionCallbackCalledWithCheck, 5000 /* 5 seconds */);

            subscribeCompletionCallback = () => {
                completionCallbackCalledWithCheck();
                if (timeoutId) {
                    clearTimeout(timeoutId);
                }
            };
        }
        const mailboxInfo = subscription.mailboxInfo ?? getGlobalSettingsAccountMailboxInfo();
        const subscribeForAllMailboxes = !!subscription.subscribeForAllMailboxes;

        const allSubscriptionPromises = [];
        let mailboxesToProcess = [mailboxInfo];
        if (subscribeForAllMailboxes) {
            mailboxesToProcess = getMailboxInfoForSettingsAccounts();
            MultiAccountSubscriptions.addSubscription(subscription, callback);
        }
        for (const mailboxInfoToProcess of mailboxesToProcess) {
            const supportedConnectionTypes = getSupportedConnectionTypesForNotification(
                mailboxInfoToProcess,
                subscription.subscriptionParameters.NotificationType
            );
            for (const connectionType of supportedConnectionTypes) {
                MailboxBasedSubscriptionTracker.add(
                    mailboxInfoToProcess,
                    subscription,
                    callback,
                    connectionType
                );

                allSubscriptionPromises.push(
                    new Promise<void>(resolve =>
                        registerSubscription(
                            mailboxInfoToProcess,
                            subscription,
                            connectionType,
                            resolve
                        )
                    )
                );
            }

            Promise.all(allSubscriptionPromises).then(subscribeCompletionCallback);
        }
    }
}
