import type { AttachmentTableEntry } from '../schema';
import { getDatabase } from '../database';
import type { AttachmentIdAndPersistenceId } from './getAttachmentIdAndPersistenceId';

export async function saveAttachmentToDatabase(
    attachmentIdAndPersistenceId: AttachmentIdAndPersistenceId,
    attachment: AttachmentTableEntry
) {
    const attachmentDatabase = await getDatabase(attachmentIdAndPersistenceId.persistenceId);
    await attachmentDatabase.attachments.put(attachment);
    return attachment;
}
