import { FileSystemStorageStrategy } from './FileSystemStorageStrategy';
import type { StorageStrategy } from './StorageStrategy';
import { StorageType } from './StorageType';

export class StorageStrategyFactory {
    createStorageStrategy(storageType: StorageType, rootDirectoryHandle: any): StorageStrategy {
        switch (storageType) {
            case StorageType.FileSystem:
                return new FileSystemStorageStrategy(rootDirectoryHandle);
            default:
                throw new Error('Invalid storage type');
        }
    }
}
