export interface AttachmentIdAndPersistenceId {
    attachmentId: string | null;
    persistenceId: string | null; // the persistence id of an account.
}

export function getAttachmentIdAndPersistenceId(url: string): AttachmentIdAndPersistenceId {
    const attachmentUrl = new URL(url);
    const searchParams = new URLSearchParams(attachmentUrl.search);
    const attachmentId = searchParams.get('id');
    const persistenceId = searchParams.get('persistenceId');
    return {
        attachmentId,
        persistenceId,
    };
}
