import type NotificationType from 'owa-service/lib/contract/NotificationType';
import { logUsage } from 'owa-analytics';
import { isFeatureEnabled } from 'owa-feature-flags';
import type { MailboxInfo } from 'owa-client-types';
import { ConnectionType } from '../schema/ConnectionType';
import {
    isNetCoreVersionSupportedInOwa,
    shouldConnectNetCoreVersionInShadow,
} from '../utils/shouldConnectNetCoreVersion';

function isOnosSupportedNotificationType(
    notificationType: NotificationType,
    mailboxInfo: MailboxInfo
): boolean {
    return isFeatureEnabled('fwk-onossignalr', mailboxInfo)
        ? notificationType === 'NewMailNotification' ||
              notificationType === 'CalendarItemNotification' ||
              notificationType === 'HierarchyNotification' ||
              notificationType === 'ReminderNotification' ||
              notificationType === 'FlexibleWorkingHoursNotification' ||
              notificationType === 'ReactionNotification' ||
              notificationType === 'ActivityFeedNotification' ||
              notificationType === 'RowNotification'
        : false;
}

export function getSupportedConnectionTypesForNotification(
    mailboxInfo: MailboxInfo,
    notificationType?: NotificationType
): ConnectionType[] {
    const defaultSupportedConnectionTypes = [];
    if (isNetCoreVersionSupportedInOwa(mailboxInfo)) {
        defaultSupportedConnectionTypes.push(ConnectionType.OwaNetCoreSignalR);
    } else {
        defaultSupportedConnectionTypes.push(ConnectionType.OwaClassicSignalR);
    }
    if (shouldConnectNetCoreVersionInShadow(mailboxInfo)) {
        defaultSupportedConnectionTypes.push(ConnectionType.OwaNetCoreSignalR);
    }
    if (notificationType) {
        if (isOnosSupportedNotificationType(notificationType, mailboxInfo)) {
            defaultSupportedConnectionTypes.push(ConnectionType.ONOSSignalR);
        }
    } else {
        logUsage('SignalRStats Undefined notificationType');
    }
    return defaultSupportedConnectionTypes;
}
