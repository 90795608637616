export interface AttachmentSettingsTableEntry<TSetting> {
    name: string;
    data: TSetting;
    creationTime?: Date;
}

export const SETTINGS_NAME_KEY = 'name';
export const SETTINGS_ROOT_DIRECTORY_HANDLE = 'rootDirectoryHandle';

export const attachmentSettingsSchema = {
    settings: `${SETTINGS_NAME_KEY}`,
};
