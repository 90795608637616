/**
 * Function to construct a plain object from a Map. This is needed because `JSON.stringify` does not work with Maps.
 * @param map Map to be mapped to plain object
 * @returns Plain Object with the same key-value pairs as the input map
 */
export const mapToObj = (map: Map<string | number, number>) => {
    const obj: Record<string | number, number> = {};
    for (const key of map.keys()) {
        obj[key] = map.get(key) ?? -1;
    }
    return obj;
};
