/* eslint-disable-next-line @typescript-eslint/no-restricted-imports -- (https://aka.ms/OWALintWiki)
 * BASELINE. Do not copy and paste!
 *	> 'dexie' import is restricted from being used. Importing from dexie is allowed but needs an additional approver */
import Dexie from 'dexie';
import type { Floor } from 'hybridspace-common/lib/serviceTypes';

const DB_NAME = 'placesMaps';
const TABLE_NAME = 'placesMaps';

export interface PlacesMapsTableEntry {
    floorId: string;
    expirationTime: number;
    floor: Floor;
}

export const placesMapsDB = new Dexie(DB_NAME);

placesMapsDB.version(1).stores({
    placesMaps: 'floorId, expirationTime', // index paths
});

export const placesMapsTable = placesMapsDB.table(TABLE_NAME);

export async function deletePlacesMapsCache() {
    if (await Dexie.exists(DB_NAME)) {
        await Dexie.delete(DB_NAME);
    }
}
