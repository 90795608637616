/* eslint-disable-next-line @typescript-eslint/no-restricted-imports -- (https://aka.ms/OWALintWiki)
 * BASELINE. Do not copy and paste!
 *	> 'dexie' import is restricted from being used. Importing from dexie is allowed but needs an additional approver */
import Dexie from 'dexie';
import type { Table } from 'dexie';
import { deleteDatabase } from 'owa-database-manager/lib/utils/deleteDatabase';
import { addInfoToErrors } from 'owa-database-utils/lib/addInfoToErrors';
import { enforceVersioningWithoutLogging } from 'owa-database-utils/lib/enforceVersioningWithoutLogging';
import { attachmentSettingsSchema } from './schema';
import type { AttachmentSettingsTableEntry } from './schema';

const DATABASE_NAME = 'owa-attachment-settings';

export class AttachmentSettingsDatabase extends Dexie {
    settings!: Table<AttachmentSettingsTableEntry<any>, string>;

    constructor(name: string) {
        super(name);
        this.version(1).stores({ ...attachmentSettingsSchema });

        addInfoToErrors(this);
        enforceVersioningWithoutLogging(this);
    }
}

let database: AttachmentSettingsDatabase | undefined;

export function getAttachmentSettingsDatabase(): AttachmentSettingsDatabase {
    if (!database) {
        database = new AttachmentSettingsDatabase(DATABASE_NAME);
    }
    return database;
}

export function deleteAttachmentSettingsDatabase(): Promise<void> {
    return deleteDatabase(DATABASE_NAME);
}
