import type { MailboxInfo } from 'owa-client-types';
import { getAccountScopeUserSettings } from 'owa-session-store';
import { setAccountConfiguration } from 'owa-session-store/lib/actions/setAccountConfiguration';
import type { AppDatabase } from 'owa-offline-database';
import type OwaUserConfiguration from 'owa-service/lib/contract/OwaUserConfiguration';
import type { ReadOnlyAccountConfiguration } from 'owa-session-store';
import { loadSetting } from 'owa-offline-settings';

export async function areAccountSettingsLoaded(
    database: AppDatabase,
    mailboxInfo: MailboxInfo
): Promise<boolean> {
    const accountScopeUserSettings: ReadOnlyAccountConfiguration =
        getAccountScopeUserSettings(mailboxInfo);

    if (!downloadSettingsExist(accountScopeUserSettings)) {
        const userConfig = await loadSetting<OwaUserConfiguration>(database, 'userConfig');

        if (userConfig) {
            setAccountConfiguration('OfflineSettings', mailboxInfo, userConfig, false);

            const newApplicationSettings = getAccountScopeUserSettings(mailboxInfo);

            return downloadSettingsExist(newApplicationSettings);
        }
        return false;
    }
    return true;
}

function downloadSettingsExist(accountSettings?: ReadOnlyAccountConfiguration) {
    return (
        !!accountSettings?.ApplicationSettings?.DownloadUrlBase &&
        !!accountSettings?.ApplicationSettings?.FirstDownloadToken
    );
}
