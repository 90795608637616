import { logGreyError, PerformanceCoreDatapoint } from 'owa-analytics';
import { type MailboxInfo } from 'owa-client-types';
import type { OwaDate } from 'owa-datetime';
import { getISODateString } from 'owa-datetime';
import type { CustomCheckpointCode, CustomWaterfallRange } from 'owa-analytics-types';
import {
    getManagedPerfDatapoint,
    type ManagedPerfDatapointType,
} from 'owa-analytics-managed-perf-datapoint';

export enum WorkPlansDailyPeekPerfWaterfallPoint {
    WorkPlansDailyPeekStartRender = 1,
    WorkLocationsSegmentRendered = 2,
    OthersInOfficeStartRender = 3,
    OthersInOfficeDataReturned = 4,
    OthersInOfficeRendered = 5,
    SuggestionsStartRender = 6,
    SuggestionsRendered = 7,
    SuggestionsCodeStart = 8,
    SuggestionsCodeEnd = 9,
    FirstSuggestionLoaded = 10,
}

const workPlansDailyPeekWFIndexToCheckpoint = (index: CustomWaterfallRange) =>
    WorkPlansDailyPeekPerfWaterfallPoint[index];

let managedPerfDatapointHandler: ManagedPerfDatapointType | undefined = undefined;

let numPeopleToRenderOIO = 0;
let numPeopleRenderedOIO = 0;
let numDailyPeekContainerResizes = 0;
let peopleRenderedTimings: number[] = [];

// Generalized tracking of expected WF markers before allowing DP to end
const defaultExpectedMarkersBeforeEnd = [
    WorkPlansDailyPeekPerfWaterfallPoint.WorkPlansDailyPeekStartRender,
    WorkPlansDailyPeekPerfWaterfallPoint.WorkLocationsSegmentRendered,
];

//Track suggestion render timings in a generalized way
const suggestionRenderedTimingMap = new Map<string, number>();

const beforeDpEnd = (dp: PerformanceCoreDatapoint) => {
    if (numPeopleToRenderOIO > 0) {
        dp.addCustomData({
            numPeopleRenderedOIO,
            peopleRenderedTimings: peopleRenderedTimings.join(','),
        });
    }
    dp.addCustomData({
        numDailyPeekContainerResizes,
        suggestionRenderedTimings: JSON.stringify(mapToObj(suggestionRenderedTimingMap)),
    });
    resetDatapointReferences();
};

const allowDpEnd = () => {
    return numPeopleRenderedOIO >= numPeopleToRenderOIO;
};

export function createWorkPlansDailyPeekPerfDatapoint(
    dateOfWorkPlansDailyPeek: OwaDate,
    customStartTimeForPerfWaterfall: number,
    isHybridspaceExternalHooksLoaded: boolean,
    mailboxInfo: MailboxInfo
) {
    if (managedPerfDatapointHandler?.getDatapoint()?.hasEnded) {
        resetDatapointReferences();
    }

    if (managedPerfDatapointHandler && !managedPerfDatapointHandler.getDatapoint()?.hasEnded) {
        // User dismissed the previous one and opened a new one, so we should end the previous one
        managedPerfDatapointHandler?.cancelledByUser();
    }

    const workPlansDailyPeekPerfDataPoint = new PerformanceCoreDatapoint(
        'WorkPlansDailyPeekPerfWaterfall',
        {
            customStartTime: customStartTimeForPerfWaterfall,
            mailbox: mailboxInfo,
        }
    );
    const workPlansDailyPeekDate = getISODateString(dateOfWorkPlansDailyPeek);
    workPlansDailyPeekPerfDataPoint.addCustomData({
        workPlansDailyPeekDate,
        isHybridspaceExternalHooksLoaded,
    });
    managedPerfDatapointHandler = getManagedPerfDatapoint(
        workPlansDailyPeekPerfDataPoint,
        defaultExpectedMarkersBeforeEnd,
        workPlansDailyPeekWFIndexToCheckpoint,
        beforeDpEnd,
        undefined /* onNotifyMarker */,
        undefined /* onExpectMarkerBeforeEnd */,
        allowDpEnd /* customAllowEndDatapointCb */
    );
}

export function getManagedPerfDatapointHandler() {
    return managedPerfDatapointHandler;
}

export function setWorkPlansDailyPeekPerfWaterfallState(
    point: WorkPlansDailyPeekPerfWaterfallPoint,
    waitForNextPaint = false,
    discardMarkerIfDefined = true
) {
    managedPerfDatapointHandler?.addCustomWaterfallAndEndIfPossible(
        point,
        waitForNextPaint,
        discardMarkerIfDefined
    );
}

export function setIncludeOthersInOfficeInPerfWaterfall() {
    expectWaterfallMarkerBeforeEnd(WorkPlansDailyPeekPerfWaterfallPoint.OthersInOfficeRendered);
    const dp = managedPerfDatapointHandler?.getDatapoint();
    if (dp && !dp.hasEnded && !dp.hasCustomData('willIncludeOIO')) {
        dp.addCustomData({
            willIncludeOIO: true,
        });
    }
}

export function setIncludeSuggestionsInPerfWaterfall() {
    expectWaterfallMarkerBeforeEnd(WorkPlansDailyPeekPerfWaterfallPoint.SuggestionsRendered);
    expectWaterfallMarkerBeforeEnd(WorkPlansDailyPeekPerfWaterfallPoint.FirstSuggestionLoaded);
    const dp = managedPerfDatapointHandler?.getDatapoint();
    if (dp && !dp.hasEnded && !dp.hasCustomData('willIncludeSuggestions')) {
        dp.addCustomData({
            willIncludeSuggestions: true,
        });
    }
}

export function flagSuggestionRendered(suggestionName: string) {
    if (!!managedPerfDatapointHandler) {
        suggestionRenderedTimingMap.set(
            suggestionName,
            managedPerfDatapointHandler?.getDatapoint()?.calculateTotalDuration() ?? 0
        );
        managedPerfDatapointHandler.addCustomWaterfallAndEndIfPossible(
            WorkPlansDailyPeekPerfWaterfallPoint.FirstSuggestionLoaded,
            true /* waitForNextPaint */,
            false /* discardMarkerIfDefined */
        );
    } else {
        logGreyError('WorkPlansDailyPeekSuggestionRenderedAfterDPEnd', undefined /* error */, {
            suggestionName,
        });
    }
}

/**
 * This function flags that a given Custom Waterfall index (marker) should be defined before ending the datapoint
 * or in other words, we need to wait for it before ending the datapoint.
 *
 * @param marker Custom Waterfall index that we will wait to be defined before ending the datapoint
 */
export function expectWaterfallMarkerBeforeEnd(marker: WorkPlansDailyPeekPerfWaterfallPoint) {
    managedPerfDatapointHandler?.expectMarkerBeforeEnd(marker);
}

export function getSuggestionsCustomCheckpointCodeForRender(): CustomCheckpointCode | undefined {
    if (!managedPerfDatapointHandler) {
        return undefined;
    }
    const dp = managedPerfDatapointHandler.getDatapoint();
    if (!dp) {
        return undefined;
    }
    return {
        name: 'SuggestionsLayout',
        datapoint: dp,
        indexes: [
            WorkPlansDailyPeekPerfWaterfallPoint.SuggestionsCodeStart,
            WorkPlansDailyPeekPerfWaterfallPoint.SuggestionsCodeEnd,
        ],
    };
}

export function setSuggestionsModuleAvailable(isAvailable: boolean) {
    managedPerfDatapointHandler?.getDatapoint()?.addCustomData({
        isSuggestionsModuleAvailable: isAvailable,
    });
}

export function setNumPeopleToRenderOIOInPerfWaterfall(numPeople: number) {
    if (numPeople !== numPeopleToRenderOIO) {
        managedPerfDatapointHandler
            ?.getDatapoint()
            ?.addCustomData({ numPeopleToRenderSuggestions: numPeople });
        numPeopleToRenderOIO = numPeople;
    }
}

export function countPeopleRenderedOIO() {
    numPeopleRenderedOIO++;
    peopleRenderedTimings.push(
        Date.now() - (managedPerfDatapointHandler?.getDatapoint()?.getStartTime() ?? 0)
    );
    managedPerfDatapointHandler?.endDatapointIfAllRequiredWaterfallPointsHit(true);
}

export function countResize() {
    numDailyPeekContainerResizes++;
}

export function isWorkPlansDailyPeekPerfDatapointEnded(): boolean {
    return !managedPerfDatapointHandler || !!managedPerfDatapointHandler.getDatapoint()?.hasEnded;
}

/**
 * Function to construct a plain object from a Map. This is needed because `JSON.stringify` does not work with Maps.
 * @param map Map to be mapped to plain object
 * @returns Plain Object with the same key-value pairs as the input map
 */
const mapToObj = (map: Map<string | number, number>) => {
    const obj: Record<string | number, number> = {};
    for (const key of map.keys()) {
        obj[key] = map.get(key) ?? -1;
    }
    return obj;
};

const resetDatapointReferences = () => {
    managedPerfDatapointHandler = undefined;
    numPeopleToRenderOIO = 0;
    numPeopleRenderedOIO = 0;
    numDailyPeekContainerResizes = 0;
    peopleRenderedTimings = [];
};
