import { getUserExternalDirectoryId } from 'accelerator-aad/lib/getUserExternalDirectoryId';
import { useLiveQuery } from 'dexie-react-hooks';
import { logGreyError } from 'owa-analytics';
import { getTimestamp, startOfDay } from 'owa-datetime';
import { getUserTimeZone } from 'owa-session-store';

import { placesPeopleTable } from '../database';
import { fetchCollaborators } from '../utils/fetchCollaborators';
import { fetchTimezoneSettings } from '../utils/fetchTimezoneSettings';

import type { OwaDate } from 'owa-datetime';
/**
 * @param collaboratorType: The collaborator type we want to grab from the cache
 * @param date: the date for which we want the people
 * @returns All the collaborators for the given date range in the form of {email, location, date}
 */
export function useCollaboratorLocationFromCache(date: OwaDate, location: string) {
    const currentOwner = getUserExternalDirectoryId();
    const startOfDayTimestamp = getTimestamp(startOfDay(date));

    return useLiveQuery(
        async () => {
            try {
                const timezoneSettings = await fetchTimezoneSettings(currentOwner);

                if (timezoneSettings?.timezone !== getUserTimeZone()) {
                    await placesPeopleTable.clear();
                    return undefined;
                }

                const collaboratorEmails = await fetchCollaborators();
                return await fetchPeopleData(
                    currentOwner,
                    startOfDayTimestamp,
                    location,
                    collaboratorEmails
                );
            } catch (e) {
                logGreyError('PlacesPeopleCache_useCollaboratorLocationFromCache', e);
                return [];
            }
        },
        [currentOwner, startOfDayTimestamp, location],
        []
    );
}

/**
 * Fetch people data based on provided criteria and filter by location
 */
async function fetchPeopleData(
    currentOwner: string,
    startOfDayTimestamp: number,
    location: string,
    collaboratorEmails: string[]
) {
    const peopleData = await placesPeopleTable
        .where({
            owner: currentOwner,
            startOfDay: startOfDayTimestamp,
        })
        .toArray();

    const seen = new Set();
    return peopleData
        .filter(row => collaboratorEmails.includes(row.email) && row.location === location)
        .filter(item => {
            const key = `${item.email}-${item.date}`;
            if (seen.has(key)) {
                return false;
            } else {
                seen.add(key);
                return true;
            }
        })
        .map(row => row.email)
        .sort((a, b) => collaboratorEmails.indexOf(a) - collaboratorEmails.indexOf(b));
}
