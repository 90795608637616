import type { Attachment } from 'owa-graph-schema';
import type { AttachmentTableEntry } from 'owa-attachment-storage';

export interface DownloadStats {
    totalSize: number;
    totalItems: number;
    totalErrors: number;
    downloadTime: number[];
    isFirstSync: boolean;
}
export type SyncKeyType = any;

export const MAX_MESSAGES_PER_REQUEST = 1;

export interface AttachmentRequest {
    attachmentId: string;
    url: string;
    messageBodyId: string;
    originalAttachment: Attachment;
}

interface AttachmentExtendedTableEntry
    extends Pick<AttachmentTableEntry, 'attachmentId' | 'messageBodyId' | 'fileSize' | 'fileName'> {
    response: Response;
}
export type AttachmentResponse = AttachmentExtendedTableEntry[];
