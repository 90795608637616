import { assertNever } from 'owa-assert';
import { format, isStringNullOrWhiteSpace } from 'owa-localize';
import type { ClientAttachmentId } from 'owa-client-ids';
import {
    ATTACHMENT_DOWNLOAD_RELATIVE_URL_TEMPLATE,
    ATTACHMENT_PREVIEW_RELATIVE_URL_TEMPLATE,
    ATTACHMENT_PRINT_RELATIVE_URL_TEMPLATE,
    ATTACHMENT_THUMBNAIL_RELATIVE_URL_TEMPLATE,
} from '../constants';
import type AttachmentUrlType from '../schema/AttachmentUrlType';
import getDownloadUrlForClassicAttachment from './getDownloadUrlForClassicAttachment';
import { isMonarchMultipleAccountsEnabled } from 'owa-account-source-list/lib/flights';
import { getCoprincipalAccountPersistenceId } from 'owa-account-source-list/lib/utils/pluggableHost';

export function getAttachmentUrlForClassic(
    attachmentId: ClientAttachmentId,
    attachmentUrlType: AttachmentUrlType,
    addIsDownloadQueryParam: boolean = false,
    includeDownloadToken: boolean = true
) {
    let template: string;
    switch (attachmentUrlType) {
        case 0:
            template = ATTACHMENT_DOWNLOAD_RELATIVE_URL_TEMPLATE;
            break;
        case 1:
            template = ATTACHMENT_PREVIEW_RELATIVE_URL_TEMPLATE;
            break;
        case 2:
            template = ATTACHMENT_THUMBNAIL_RELATIVE_URL_TEMPLATE;
            break;
        case 3:
            template = ATTACHMENT_PRINT_RELATIVE_URL_TEMPLATE;
            break;
        default:
            return assertNever(attachmentUrlType);
    }

    // Add isDownload to the querystring
    if (!isStringNullOrWhiteSpace(template)) {
        const downloadUrl = getDownloadUrlForClassicAttachment(
            attachmentId,
            template,
            includeDownloadToken,
            attachmentUrlType === 3
        );
        if (downloadUrl) {
            let addPresistenceId = false;
            let persistenceId;
            if (isMonarchMultipleAccountsEnabled()) {
                // If monarch multiple accounts is enabled, we need to add the persistenceId to the query string
                // so that a service worker can use it to support multiple accounts.
                persistenceId = getCoprincipalAccountPersistenceId(attachmentId.mailboxInfo);
                if (persistenceId) {
                    addPresistenceId = true;
                }
            }

            return format(
                '{0}{1}{2}{3}',
                downloadUrl,
                addIsDownloadQueryParam ? '&isDownload=true' : '',
                '&animation=true', // This will make the API return the full image if it is a gif
                addPresistenceId ? '&persistenceId=' + persistenceId : ''
            );
        }
    }

    return null;
}
