export function createResponseFromBlob(fileName: string, blob: Blob) {
    const headers = new Headers();
    headers.append('Content-Disposition', `attachment; filename="${fileName || 'NONAME'}"`);
    headers.append('Content-Length', blob.size.toString());

    const content = structuredClone(blob);
    const response = new Response(content, {
        headers,
    });

    return response;
}
