import { useListUserFeatureAccessSelector } from 'places-analytics-app-store';
import { ADMIN_ROOT, SPACE_ANALYTICS, SPACE_MANAGEMENT } from 'hybridspace-common/lib/constants';
import { isSpaceAnalyticAdminTabEnabled } from 'hybridspace-common/lib/utils';

export function useDefaultAdminScreenId() {
    const { featureAccess } = useListUserFeatureAccessSelector();
    const isSpaceAnalyticAdminEnabled = isSpaceAnalyticAdminTabEnabled(featureAccess);

    const defaultAdminTab = isSpaceAnalyticAdminEnabled ? SPACE_ANALYTICS : SPACE_MANAGEMENT;

    return `/${ADMIN_ROOT}/${defaultAdminTab}`;
}
