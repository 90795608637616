import type { Attachment } from 'owa-graph-schema';
import { getExtensionWithoutDotFromFileNameForLogging } from 'owa-file';

export function logBlockedAttachment(
    attachment: Attachment,
    reason: string,
    logUsage: (logName: string, logData: any) => void
) {
    logUsage('CnexOfflineBlockedAttachment', {
        extension: getExtensionWithoutDotFromFileNameForLogging(attachment.Name || ''),
        attachmentId: attachment.AttachmentId?.Id || '',
        reason,
    });
}
