import type { LicensingMailboxInfo } from 'owa-account-source-list-types';
import getAccountSourceListStore from '../store/accountSourceListStore';

/**
 * Returns all of condfigured office identities that are used for licensing by
 * the application. This list will include accounts that are in the account
 * source list store and the pending accounts.
 * @returns All of the configured office identities for licensing.
 */
export function getAllOfficeIdentities(): LicensingMailboxInfo[] {
    return getAccountSourceListStore().licensingIdentities;
}
